import React, {useEffect, useState, useContext} from 'react';
import axios from 'axios';
import '../../App.css';
import { UserContext } from '../../store/UserContext';
import { PencilIcon } from '@heroicons/react/outline'
import ModalCustom from '../modal/modalCustom'
//form
import FormUser from './forms/formUser'
import FormPwd from './forms/formPwd'
import { getRoleName } from '../../utils/getPermissions';

  export default function SingleUser(props) {
    const { userState } = useContext(UserContext); 
    const [data, setData]= useState({name:"",email:""})
    const [openModalUser, setOpenModalUser]=useState(false)
    const [openModalPwd, setOpenModalPwd]=useState(false)


    useEffect(()=>{
        axios({
            method:'post',
            headers: { 'Authorization': 'Bearer ' + userState.user.token},
            url:`${process.env.REACT_APP_ADMIN_URL}/apiFront/users/getOneUser`,
            data:{"id": props.match.params.id}
        })
        .then(function (response) {
            // console.log("RESPONSE",response.data)
            setData(response.data.data)
        })
        .catch(function (error) {
            console.log("error", error)

            console.log(error);
        });
    },[userState.user.token,props.match.params]);
    //Function to close userCard

    const closeModal = ()=>{
        setOpenModalUser(false)
        setOpenModalPwd(false)
    }

    //Function to update 
    const updateUser = (userDataFromBack)=>{
        setData(userDataFromBack)
    }
return (
    <div className="flex justify-center">
        <div className=" w-3/4 bg-indigo-50 h-auto pb-10 rounded-xl">
            <div className="flex justify-end mt-5 mr-5">
                <button className="text-gray-600"
                    onClick={()=>setOpenModalUser(true)}
                >
                    <PencilIcon className="h-6 w-6" />
                </button>
            </div>
            <ModalCustom openModal={openModalUser}  modalFunctioToClose={closeModal} form={<FormUser user={data} formFunctioToClose={closeModal} dataReturn={updateUser}></FormUser>}></ModalCustom>
            <ModalCustom openModal={openModalPwd}  modalFunctioToClose={closeModal} form={<FormPwd user={data} formFunctioToClose={closeModal}/>}> </ModalCustom>
            <div className="px-10 pt-5">
                <h3 className='text-lg font-semibold'>Informations générales</h3>
                    <div className="flex mt-5 ml-5">
                        <h6>Prénom:</h6>
                        <p className="mx-5">{data.name}</p>
                    </div>
                    <div className="flex mt-5 ml-5">
                        <h6>Email:</h6>
                        <p className="mx-5">{data.email}</p>
                    </div>
                    <div className="flex mt-5 ml-5">
                        <h6>Rôle:</h6>
                        <p className="mx-5">{getRoleName(data.role)}</p>
                    </div>
                    <div className="flex mt-5 ml-5">
                        <h6>mot de passe:</h6>
                        <p className="mx-5">
                            <button className="text-blue-500 background-transparent font-bold"
                            onClick={()=>setOpenModalPwd(true)}
                            >Modifier le mot de passe</button>
                        </p>
                    </div>
        
            </div>
        </div>
   </div>
  )

    }
