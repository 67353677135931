import React from 'react';
import '../../index.css';
import {Link} from 'react-router-dom'

export default function CardCustom(props) {


return (
  
        <div className="flex flex-col justify-between shadow border rounded-lg">
            <div className="flex items-start space-x-4 p-4">
                <div className="flex items-center p-4 bg-blue-600 text-white rounded-lg">
                    {props.icon}
                </div>
                <div className="flex-1">
                    <p className="text-gray-500 font-semibold">{props.title}</p>
                    <div className="flex items-baseline space-x-4">
                        <h2 className="text-2xl font-semibold">
                            {props.mainValue}
                        </h2>
                        <div className='flex flex-col'>
                            <p className="font-semibold space-x-1">
                                {/* <span>&#8593;</span> */}
                                {props.value1[0]}:{' '}  
                                <span className="font-bold">{props.value1[1]}</span>
                            </p>
                            <p className="font-semibold space-x-1">
                                {/* <span>&#8593;</span> */}
                                {props.value2[0]}: {' '}
                                <span className="font-bold">{props.value2[1]}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {props.link?
                <Link to='/Clients'>
                    <button  
                        // className="btn-indigo"
                        className="block p-3 text-lg font-semibold bg-blue-50 text-blue-800 hover:bg-blue-100 cursor-pointer"
                    >
                        Voir tout
                    </button>
                </Link>
            :
            null
           }
        </div>
  )

    }
// }