/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, UserIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import logo from '../../Ammareal-Monogramme-RVB-Clementine-Mint.png'

//Store
import { UserContext } from '../../store/UserContext';
//utils
import { getNavigation, getProfilNav } from '../../utils/getPermissions'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar(props) {
    const { userState, userDispatch } = useContext(UserContext); 
    const navigation = getNavigation(userState.user.role)
    const profile = getProfilNav(userState.user.role)
    const [itemFocus,setItemFocus]=useState(0);
    
    // console.log('NAV PROPS',props)

    useEffect(()=>{
      //To focus navBar element
      var reprise = new RegExp('reprise', 'i'),
          client = new RegExp('client', 'i'),
          administration = new RegExp('administration', 'i'),
          dashboard = new RegExp('/', 'i'),
          stats = new RegExp('stats', 'i'),
          rattrapage = new RegExp('rattrapage', 'i'),
          path = props.page.path

      switch(true){
        case reprise.test(path) :
          setItemFocus(1);
          break;
        case rattrapage.test(path) :
          setItemFocus(4);
          break;
        case client.test(path) :
          setItemFocus(2);
          break;
        case administration.test(path) :
            setItemFocus(-1);
            break;
        case stats.test(path) :
          setItemFocus(3);
          break;
        case dashboard.test(path) :
          setItemFocus(0);
          break;
        default:
          setItemFocus()
      }
    },[props])

  return (
    <div>
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="container">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8"
                      src={logo}
                      alt="Workflow"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item, itemIdx) =>{
                        var classNameItemNav;
                        var link = itemIdx===0?'/':"/"+navigation[itemIdx];
                            itemFocus === itemIdx ? classNameItemNav ="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                            :
                            classNameItemNav="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium";
                            return(
                                    <Link to={link} key={itemIdx}> 
                                        <button className={classNameItemNav}
                                            onClick={()=>{setItemFocus(itemIdx)}}
                                        >
                                            {item}
                                        </button>
                                    </Link>
                                )
                            }
                        )}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <p className='text-gray-100'>Bonjour {userState.user.name}</p>
                    {/* <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                              <span className="sr-only">Open user menu</span>
                              <UserIcon className="h-6 w-6 text-gray-400" aria-hidden="true"></UserIcon>
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {profile.map((item) =>{
                                //  
                                // console.log("menu profil",item);
                                var linkProfil = item==="Logout"?'/':"/"+item;
                                return (      <Menu.Item key={item}>
                                        {({ active }) => (
                                            <Link to={linkProfil}>
                                                <button
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-gray-700'
                                                )}
                                                onClick={()=>{
                                                    setItemFocus(-1)
                                                    if(item==='Logout'){
                                                        userDispatch({type:'logout'})
                                                    }
                                                }
                                                }
                                                >
                                                {item}
                                                </button>
                                            </Link>
                                        )}
                                        </Menu.Item>
                                    )
                                    }
                                )}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  )
}
