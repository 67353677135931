import React from 'react';
import '../../index.css';
  
export default function Main({children}) {
    

        return (
            <main  className="container py-6 ">
                {children}
            </main>
               
          )
    }
