import React, {useEffect, useState} from 'react';
import { axiosConfig, axiosConfigEtiquette } from '../../../utils/axiosConfig'
import Alert from '@mui/material/Alert';
import { useHistory } from 'react-router-dom';
import '../../../App.css';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@mui/material/Snackbar';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CircularProgress } from '@mui/material';

//NB : dataRefurbs est tableau de valeurs, l'accès au data se fait par index. Lorsqu'une data est ajoutée à ce tableau, l'index des data est modifiée.
//cela impacte plusieurs fonction : isRowSelectable, onRowSelectionChange, cellClicked. Il faut donc penser à les mettre à jour à chaque ajout ou modification d'ordonnancement des données du tableau

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
const myTheme =  createMuiTheme({

    overrides: {
        MuiTableBody: {
        root: {
          cursor:'pointer'
        },
      }
    }
  });

  export default function AllRefurbs() {
    let history = useHistory();
    const [loader,setLoader] = useState(false);
    const [dataRefurbs, setDataRefurbs]= useState([])
    const [copy, setCopy] = useState(false);

    const [count, setCount] = useState(0);
    const [rowsPerPage,setRowsPerPage] = useState(10);
    const [sortOrder, setSortOrder]= useState({});
    const [pageToDisplay,setPageToDisplay]= useState(0);
    const [regex, setRegex] = useState(history.location.hash.replace('#','')||null);
    const [filterRange, setFilterRange]= useState(null);
    const [filterRefurbStatus, setFilterRefurbStatus]= useState(null);

    const [regexWait,setRegexWait] = React.useState("")
    const [regexWait2,setRegexWait2] = React.useState(null)
    // const [regexSaved, setRegexSaved] = React.useState(history.location.hash.replace('#',""))
    

    const columns  = [
        {
            name: "ID reprise",
            field: "_id",
            options: {
             filter: false,
             sort: true,
             
             customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <div className="flex flex-row items-center space-x-2 justify-between">
                        <p  
                            onClick={(e)=> navigateToRefurbDetails(e.ctrlKey, value)}
                        >
                            {value.substring(0, 8)} 
                        </p>
                        <CopyToClipboard
                            text={value}
                            onCopy={()=>{
                                setCopy(true)
                                setTimeout(()=>{
                                    setCopy(false)
                                },2000)}
                            }
                        >
                        <p 
                            data-clipboard-target="#idRefurb"
                            className="btn border border-black rounded-xl ml-auto p-1 text-xs hover:bg-gray-700 hover:text-white "
                        >
                            Copier
                        </p>
                        </CopyToClipboard>
                    </div>
                
                )
            }
            }
           },
        {
         name: "N° d'expédition Mondial Relay - tracing",
         field: "mondialRelayExpNb",
         options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <div   className="flex justify-center items-center">
                    <p>{value[0]} / {value[1]}</p>
                </div>
            );
        }
         }
        },
       {
        name: "Date de création",
        field: "createdAt",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                    var date = value.split("").splice(0,10).join("").split("-").reverse().join("/")
                        return (
                            <p>{date}</p>
                        )
            },
            // filterType: 'custom',
            // customFilterListOptions: {
            //     render: v => {
            //       if (v[0] && v[1] && state.dateFilterChecked) {
            //         return [`Date min: ${moment(v[0]).format('DD/MM/YYYY')}`, `Date max: ${moment(v[1]).format('DD/MM/YYYY')}`];
            //       } else if (v[0] && v[1] && !state.dateFilterChecked) {
            //         return `Date min: ${moment(v[0]).format('DD/MM/YYYY')} - Date max: ${moment(v[1]).format('DD/MM/YYYY')}`;
            //       } else if (v[0]) {
            //         return `Date min: ${moment(v[0]).format('DD/MM/YYYY')}`;
            //       } else if (v[1]) {
            //         return `Date max: ${moment(v[1]).format('DD/MM/YYYY')}`;
            //       }
            //       return [];
            //     },
            //     update: (filterList, filterPos, index) => {
            //       console.log('customFilterListOnDelete: ', filterList, filterPos, index);
    
            //       if (filterPos === 0) {
            //         filterList[index].splice(filterPos, 1, '');
            //       } else if (filterPos === 1) {
            //         filterList[index].splice(filterPos, 1);
            //       } else if (filterPos === -1) {
            //         filterList[index] = [];
            //       }
    
            //       return filterList;
            //     },
            //   },
            //   filterOptions: {
            //     names: [],
            //     logic(date, filters) {
            //       if (filters[0] && filters[1]) {
            //         return date < filters[0] || date > filters[1];
            //       } else if (filters[0]) {
            //         return date < filters[0];
            //       } else if (filters[1]) {
            //         return date > filters[1];
            //       }
            //       return false;
            //     },
            //     display: (filterList, onChange, index, column) => (
            //       <div>
            //         <FormLabel>Date de création</FormLabel>
            //         <FormGroup row>
            //             <div>
            //                 <p>Min</p>
            //                 <TextField
            //                     // label='min'
            //                     type="date"
            //                     value={filterList[index][0] || ''}
            //                     onChange={event => {
            //                     filterList[index][0] = event.target.value;
            //                     onChange(filterList[index], index, column);
            //                     }}
            //                     style={{ width: 200, marginRight: '5%' }}
            //                 />
            //             </div>
            //             <div>
            //                 <p>Max</p>
            //                 <TextField
            //                     type="date"
            //                     // label='max'
            //                     value={filterList[index][1] || ''}
            //                     onChange={event => {
            //                     filterList[index][1] = event.target.value;
            //                     onChange(filterList[index], index, column);
            //                     }}
            //                     style={{ width: 200 }}
            //                 />
            //             </div>
            //           <FormControlLabel
            //             control={
            //               <Checkbox
            //                 checked={state.dateFilterChecked}
            //                 onChange={event => setState({ dateFilterChecked: event.target.checked })}
            //               />
            //             }
            //             label='Valeurs séparées'
            //             style={{ marginLeft: '0px' }}
            //           />
            //         </FormGroup>
            //       </div>
            //     ),
            //   },
            // 
            },
        },
        {
        name: "Client",
        field: "customer",
        options: {
            filter: false,
            sort: true,
            }
        },
        {
            name: "Nb d'ouvrages",
            // field: "bookSold",
            options: {
                filter: false,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{width:100}} className="flex flex-col justify-center items-start">
                            <p>Attendu: {value[0]}</p>
                            <p>Accepté: {value[1]}</p>
                            <p>Rejeté: {value[2]}</p>
                        </div>
                    );
                }
            }
        },
        {
            name: "Total reprise en €",
            // field: "bookSold",
            options: {
                filter: false,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div  style={{width:100}} className="flex flex-col justify-center items-start">
                            <p>Estimé: {value[0]} €</p>
                            <p>Réel: {value[1]} €</p>
                        </div>
                    );
                }
            }
        },
        {
            name: "Statut reprise",
            field: "customerPaid",
            options: {
                sort: true,
                // filterType: 'custom',
                filter: true,
                filterOptions: {
                    names:  ["Payé", "A payer","Non trié"],
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        value?
                        <div className="flex justify-center text-center">
                            <span 
                                className={classNames(value==="A payer"?"bg-red-600":value==="Payé"?"bg-green-600":"bg-purple-600",
                                            "inline-flex px-3 py-2 text-xs font-bold leading-none text-white rounded-full w-max")}
                            >
                                {value}
                            </span>
                        </div>
                        :
                        null
                    );
                }
            }
        },
        {
        name: "Bon de reprise",
        field: "refurbPdf",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <button className="inline-flex items-center bg-transparent text-black text-opacity-70 font-semibold hover:bg-black hover:bg-opacity-70 hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded">
                        <span className=" ">Bordereau de reprise</span>
                        <svg className="fill-current w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  </button>
                );
              }
            }
        }
       ];
       const fetchPdfRefurb = (id) =>{
        axiosConfigEtiquette.get(`/apiFront/refurb/pdfRefurb/${id}`)
            .then(response => {
                //Create a Blob from the PDF Stream
                var file = new Blob([response.data], {type: 'application/pdf'});
                window.open(URL.createObjectURL(file));
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(()=>{
        setLoader(true)
        var requestOptions = {"page":pageToDisplay,"sortOrder":sortOrder,"rowsPerPage":rowsPerPage, "regex":regex, "filterRange":filterRange, "filterRefurbStatus":filterRefurbStatus}

        axiosConfig.post('apiFront/backoffice/api/refurbs/pagination', requestOptions)
        .then(function (response) {
            // console.log("RESPONSE",response.data)
            setDataRefurbs(response.data.dataRefurbs)
            setCount(response.data.count)
            setLoader(false)

        })
        .catch(function (error) {
            console.log(error);
            setLoader(false)

        });
    },[pageToDisplay, sortOrder,rowsPerPage, regex, filterRange, filterRefurbStatus ]);

    const cellClicked = (colData, cellMeta)=>{
        // console.log("event dataTable", cellMeta)
        if(cellMeta.colIndex === 7){
            fetchPdfRefurb(dataRefurbs[cellMeta.dataIndex][0])
        }else if (cellMeta.colIndex !== 0){
            navigateToRefurbDetails(cellMeta.event.ctrlKey, dataRefurbs[cellMeta.dataIndex][0])
        }
           
     };

    const options = {
        searchText: regexWait||history.location.hash.replace('#',""),
        serverSide: true,
        count: count,
        page: pageToDisplay,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10,30,50],
        viewColumns: false,
        print:false,
        onCellClick: cellClicked,

        // rowsSelected: 
        onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData)=>{
            // console.log("changedColumnIndex", changedColumnIndex,type,filterList)
            if(changedColumnIndex===6){
                if(filterList[changedColumnIndex].length>0){
                    setFilterRefurbStatus(filterList[changedColumnIndex][0])
                }else{
                    setFilterRefurbStatus(null)
                }
            }else if(changedColumnIndex===2){
                console.log('setFilterRang',filterList[changedColumnIndex],filterList[changedColumnIndex].length>0)

                if(filterList[changedColumnIndex].length>0){
                    setFilterRange(filterList[changedColumnIndex])
                }else{
                    setFilterRange(null)
                }
            }
        },
        onTableChange: (action, tableState) => {
            // console.log("onTableChange",action)
            switch (action) {
                case 'changePage':
                    setPageToDisplay(tableState.page)
                  break;
                case 'sort':
                    // console.log('sort not handled yet.',tableState.sortOrder);
                    setSortOrder(tableState.sortOrder)
                  break;
                case 'changeRowsPerPage':
                    // console.log('RowsPerPage not handled yet.',tableState.rowsPerPage);
                    setRowsPerPage(tableState.rowsPerPage)
                break;
                case 'search':
                    setRegexWait(tableState.searchText)
                    setTimeout(()=>{
                        sendRegex(tableState.searchText)              
                    },500)
                break;
                case 'onSearchClose':
                    setRegexWait("")
                    setRegexWait2(null)
                    history.push(`/Reprises`,null);
                    break;
                default:
                    // console.log('action not handled.');
              }
            },
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        textLabels: {
            body: {
              noMatch: loader?"En cours de chargement...":"Aucune correspondance...",
              toolTip: "Sort",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            // selectedRows: {
            //     text: ["reprise(s) sélectionnée(s)"],
            // },
        }
    };
    const navigateToRefurbDetails = (ctrlKey, id) => {
        
        if(ctrlKey){
            setTimeout(() => window.open(`/Reprise/${id}`), 5);
        }else{
            history.push(`/Reprise/${id}`,null);
        }
    }


const sendRegex = (value) => {
    if(value){
        setRegexWait2(value)
    }
}

useEffect(()=>{
    if(regexWait===regexWait2){
        setRegex(regexWait2)
    }
},[regexWait, regexWait2])

useEffect(()=>{
    if(regex){
        history.push(`/Reprises#${regex}`,null);
    }
},[regex, history])

    const loadingComponent = (
        <div style={{position: 'absolute', zIndex: 110, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.8)'}}>
          <CircularProgress size={24} />
        </div>
      );
        return (
            <div style={{position:'relative'}}>
                {loader && loadingComponent}
                <MuiThemeProvider theme={myTheme}>
                    <Snackbar open={copy} autoHideDuration={2000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                        <Alert severity="success" sx={{ width: '100%' }}>
                            ID de reprise copié :)
                        </Alert>
                    </Snackbar>
                    <MUIDataTable
                        title={"Liste des reprises"}
                        data={dataRefurbs}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
