import React from 'react';
// import axios from 'axios';
import '../App.css';
import Refurbs from '../components/refurbs/allRefurbs'
import Header from '../components/layout/header';
import Main from '../components/layout/main';

export default function RefurbsPage() {

        return (
            <>
                <Header title='Reprises' />
                <Main>
                    <Refurbs></Refurbs>
                </Main>
            </>
          )
    }
// }