import React from 'react';
// import axios from 'axios';
import '../App.css';
import Refurb from '../components/refurbs/singleRefurb'
import Header from '../components/layout/header';
import Main from '../components/layout/main';

export default function RefurbDetailsPage(props) {
        return (
            <>
                <Header title='Reprise'/>
                <Main>
                    <Refurb {...props}></Refurb>
                </Main>
            </>
          )
    }
// }