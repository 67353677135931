import React from 'react';
// import axios from 'axios';
import '../App.css';

import SingleUser from '../components/administration/singleUser'
import Header from '../components/layout/header';
import Main from '../components/layout/main';

export default function AdminDetail(props) {
    

        return (
            <>
               <Header title= 'Utilisateur' navLink='/Administration' label='Retour'></Header>
                <Main>
                    <SingleUser {...props}></SingleUser>
                </Main>
            </>
          )
    }
// }