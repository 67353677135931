import React from 'react';
// import axios from '../utils/axiosConfig';

const UserContext = React.createContext();
const localState = JSON.parse(localStorage.getItem('userState'));

const initialState = {
    user: {
            username:null,
            name: null,
            role: null,
            updatePwd:false,
            token: null
        },
    loggedIn: false,
    refresh: false 
}
function userReducer(state, action){
    switch (action.type) {
        case 'login': {
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            }
        }
        case 'unauthorized': {
            localStorage.removeItem("userState")
            return initialState
        }
        case 'logout': {
            localStorage.removeItem("userState")
            return initialState
        }
        case 'refresh': {
            return {
                ...state,
                refresh: action.payload
            }
        }
        default: {
            return state;
        }
    }
}

function UserProvider({children}) {
    const [userState, userDispatch] = React.useReducer(userReducer, localState || initialState)
   
    React.useEffect(() => {
        // console.log("USERSTATE STORE",userState)
        localStorage.setItem('userState', JSON.stringify(userState))
    }, [userState])
    
    return (
            <UserContext.Provider value={{userState, userDispatch}}>
                {children}
            </UserContext.Provider>
    )
}

export { UserProvider, UserContext }
