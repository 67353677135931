import React from 'react';
import { Button } from '@material-ui/core';
import police from '../assets/images/police.png'
import Header from '../components/layout/header';
import Main from '../components/layout/main';
import NavBar from '../components/layout/navbar';

function UnauthorizedPage(props){
    return (
        <>
        <NavBar page="null"></NavBar> 
            <Header title='Non autorisé'/>
            <Main>
                <div style={{display: "flex", flexDirection: "column", flex:1, textAlign:"center", marginTop: 20}}>
                    <div style={{display:'flex', flexDirection: 'column',alignItems: 'center',margin: 50}}>
                        <h1>
                            Vous n'avez pas les droits nécessaires pour accéder à cette page...
                        </h1>
                        <h2 >
                            {props.location.state}
                        </h2>
                        <img src={police} alt='icon lien cassé' style={{width: 100, marginTop: 50}} />

                    </div>
                
                    <Button
                        primary="true"
                        onClick={()=>props.history.goBack()}
                    >
                        Revenir à la page précédente
                    </Button>
                </div>
            </Main>
        </>
    );
    
}

export default UnauthorizedPage;