import React from 'react';
// import axios from 'axios';
// import CardStats from '../components/stats/cardStats';
import '../index.css';
import NivoChartStore from '../components/stats/nivoChartStore'
import Header from '../components/layout/header';
import Main from '../components/layout/main';

export default function Dashboard() {
    

        return (
            <>
                <Header title='Stats' />
                <Main>
                    {/* <div style={{display:"flex", flexDirection:'column',justifyContent:'center'}}>
                        <CardStats/>
                    </div> */}
                    <div className="mt-10">
                        <NivoChartStore></NivoChartStore>
                    </div>
                </Main>
            </>
          )
    }
// }