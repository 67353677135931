import React, {useEffect, useState} from 'react';
import {axiosConfig} from '../../utils/axiosConfig'
import '../../index.css';
import {Link} from 'react-router-dom'




// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
//   }

  export default function CardStats() {
    const [stats, setStats]= useState({uniqueUser:null,totalRefurb:[{totalPrice:null,sumBooks:null}],totalSoldReal:[{totalPrice:null,sumBooks:null}],statsRefurbs:[{activeTrue: null, activeFalse: null}],
                                    refurbOrigin: [], parcelTracking: [], acceptation:[]
                                })
    
    useEffect(()=>{

        axiosConfig.get('apiFront/backoffice/api')
        
        .then(function (response) {
                // console.log("RESPONSE",response.data)
                if(response.data.totalRefurb.length > 0){
                    setStats(response.data)
                }
                
        })
         .catch(function (error) {
            //  Interceptor()
            console.log(error);
        });
    },[]);


var totalPrice= (Math.round(stats.totalRefurb[0].totalPrice * 100) / 100).toString().replace('.',',');
var totalPriceReal= (Math.round(stats.totalSoldReal[0].totalPrice * 100) / 100).toString().replace('.',',');


return (
    <div className="bg-white rounded w-full">

    <div className="md:grid md:grid-cols-3 md:gap-4 space-y-4 md:space-y-0 ">

        <div className="flex flex-col justify-between shadow border rounded-lg">
            <div className="flex items-start space-x-4 p-4">
                <div className="flex items-center p-4 bg-blue-600 text-white rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" className="fill-current w-7 h-7" width="24" height="24" viewBox="0 0 24 24"><path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z"/></svg>
                </div>
                <div className="flex-1">
                    <p className="text-gray-500 font-semibold">Clients uniques</p>
                    <div className="flex items-baseline space-x-4">
                        <h2 className="text-2xl font-semibold">
                            {stats.uniqueUser}
                        </h2>
                        <p className="text-green-700 flex font-semibold space-x-1">
                            {/* <span>&#8593;</span>
                            <span className="font-bold">122</span> */}
                        </p>
                    </div>
                </div>
            </div>
            <Link to='/Clients'>
            <button  
                // className="btn-indigo"
                className="block p-3 text-lg font-semibold bg-blue-50 text-blue-800 hover:bg-blue-100 cursor-pointer"
            >
                Voir tout
            </button>
           </Link>
        </div>
        <div className="flex flex-col justify-between shadow border rounded-lg">
            <div className="flex items-start space-x-4 p-4">
                <div className="flex items-center p-4 bg-blue-600 text-white rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg"  className="fill-current w-7 h-7" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
                </svg>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="fill-current w-7 h-7" width="24" height="24" viewBox="0 0 24 24"><path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z"/></svg> */}
                </div>
                <div className="flex-1">
                    <p className="text-gray-500 font-semibold">Stats Globales</p>
                    <p className="text-gray-500">Avant Tri:</p>

                    <div className="flex items-baseline space-x-4">
                        <h2 className="text-xl font-semibold">
                            {totalPrice} €
                        </h2>
                        <p className="text-green-700 flex font-semibold space-x-1">
                            {/* <span>&#8593;</span> */}
                            <span className="font-bold">  {stats.totalRefurb[0].sumBooks} livres à racheter</span>
                        </p>
                    </div>
                    <p className="text-gray-500">Après Tri:</p>
                    <div className="flex items-baseline space-x-4">
                        <h2 className="text-xl font-semibold">
                            {totalPriceReal} €
                        </h2>
                        <p className="text-green-700 flex font-semibold space-x-1">
                            {/* <span>&#8593;</span> */}
                            <span className="font-bold">  {stats.totalSoldReal[0].sumBooks} livres acceptés au tri</span>
                        </p>
                    </div>
                    <p className='font-semibold'>
                        Taux de rejet: {100-parseFloat(stats.totalSoldReal[0].sumBooks / stats.totalRefurb[0].sumBooks).toFixed(2)*100} %</p>
                </div>
            </div>
           <Link to='/Reprises'>
           <button  className="block p-3 text-lg font-semibold bg-blue-50 text-blue-800 hover:bg-blue-100 cursor-pointer"
            >
                Voir tout
            </button>
           </Link>
        </div>
        <div className="flex flex-col justify-between shadow border rounded-lg">
            <div className="flex items-start space-x-4 p-4">
                <div className="flex items-center p-4 bg-blue-600 text-white rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
                </div>
                <div className="flex-1">
                    <p className="text-gray-500 font-semibold">Suivi Mondial Relay des reprises non triées (MAJ quotidienne)</p>
                    <div className="flex items-baseline space-x-4">
                        <div className="flex flex-col font-semibold space-x-1">
                            {
                               
                                stats.parcelTracking.map((e,i)=>{
                                    var regex=/^[0-9]{2}/
                                    var status = e.trackingParcelStatus?e.trackingParcelStatus.replace(regex,""):"";
                                    if( e.trackingParcelStatus){
                                        return <p key={i}><span className="font-bold">{status}: </span>{e.value}</p>
                                    }else{
                                        return null
                                    }
                                })

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </div>

    <div className="mt-6 md:grid md:grid-cols-3 md:gap-4 space-y-4 md:space-y-0 ">
        <div className="flex flex-col justify-between shadow border rounded-lg">
            <div className="flex items-start space-x-4 p-4">
                <div className="flex items-center p-4 bg-blue-600 text-white rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                </svg>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="fill-current w-7 h-7" width="24" height="24" viewBox="0 0 24 24"><path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z"/></svg> */}
                </div>
                <div className="flex-1">
                    <p className="text-gray-500 font-semibold">Nombre total de reprises</p>
                    <div className="flex items-baseline space-x-4">
                        <h2 className="text-2xl font-semibold">
                            {stats.statsRefurbs[0].activeTrue + stats.statsRefurbs[0].activeFalse}
                        </h2>
                        <p className="flex flex-col font-semibold space-x-1">
                            {/* <span>&#8593;</span> */}
                            <span className="text-green-700 font-bold">Trié: {stats.statsRefurbs[0].activeFalse}</span>
                            <span className="text-purple-700  font-bold">En attente: {stats.statsRefurbs[0].activeTrue}</span>

                        </p>
                    </div>
                </div>
            </div>
            <Link to='/Reprises'>
           <button  className="block p-3 text-lg font-semibold bg-blue-50 text-blue-800 hover:bg-blue-100 cursor-pointer"
            >
                Voir tout
            </button>
           </Link>
        </div>
        {/* <div className="flex flex-col justify-between shadow border rounded-lg">
            <div className="flex items-start space-x-4 p-4">
                <div className="flex items-center p-4 bg-blue-600 text-white rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                    <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                </svg>
                </div>
                <div className="flex-1">
                    <p className="text-gray-500 font-semibold">Reprises par origine</p>
                    <div className="flex items-baseline space-x-4">
                        <div className="flex flex-col font-semibold space-x-1">
                            {
                               
                                stats.refurbOrigin.map((e,i)=>{
                                    var totalOrigin = stats.refurbOrigin.reduce((count, curItem) => count + curItem.value, 0)
                                    // console.log('totalOrigin',totalOrigin)
                                    var value = e.value/(totalOrigin)*100
                                    return <p key={i}><span className="font-bold">{e.origin?e.origin:"Provenance non connue"}: </span>{value.toFixed(2)}%</p>
                                })

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="flex flex-col justify-between shadow border rounded-lg">
            <div className="flex items-start space-x-4 p-4">
                <div className="flex items-center p-4 bg-blue-600 text-white rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                </svg>
                </div>
                <div className="flex-1">
                    <p className="text-gray-500 font-semibold">Taux d'acceptation</p>
                    <div className="flex items-baseline space-x-4">
                        <h2 className="text-2xl font-semibold">
                            {stats.acceptationRate }%
                        </h2>
                        <div className="flex flex-col font-semibold space-x-1">
                            {
                                stats.acceptation.map((e,i)=>{
                                    if(e.status==="vendu"){
                                        return <p key={i} className=" text-green-700 font-bold">{e.status}: {e.value}</p>
                                    }else{
                                        return <p key={i} className="text-purple-700 font-bold">{e.status}: {e.value}</p>

                                    }
                                })

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</div>

  )

    }
// }