import React from 'react';
import '../../../App.css';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
// import moment from 'moment'
import ModalCustom from '../../modal/modalCustom';
import { UserContext } from '../../../store/UserContext';
import FormSortStatus from '../forms/FormSortStatus';
import { getPermissions } from '../../../utils/getPermissions';

  const myTheme =  createMuiTheme({
    overrides: {
        MuiTableBody: {
        root: {
          cursor:'default'
        },
      }
    }
  });


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  export default function DataTableRefurbDetail(props) {
    const { userState } = React.useContext(UserContext); 

    const [sortStatus, setSortStatus]= React.useState(null)
    const [openModalSortStatus, setOpenModalSortStatus] = React.useState(false)
    const [isbn, setIsbn] = React.useState(null)
    const [bookId, setBookId] = React.useState(null)

    const permissionStatus = getPermissions(userState.user.role, "/Reprise/:id", "statusDelete")
    // console.log("permissionStatus",userState.user.role, permissionStatus)
    // userState.user.role==="admin"?true:
    const closeModal = ()=>{
        setOpenModalSortStatus(false)
        props.getReloadData(true)
        setBookId(null)
        setIsbn(null)
    }
    // console.log("data", props.data)
    var data = props.data.map(elt=>{
        var refurbDetail = 
            [   
                [elt.isbnClient || elt.isbn13, elt.isbnCapture],
                elt._id,
                elt.title,
                elt.image,
                // elt.status,
                elt.statutTriPhysique,
                elt.userChoice,
                elt.price
            ]
        return refurbDetail
    })
    const columns  = [
        {
            name: "ISBN",
            field: "isbn13",
            options: {
             filter: false,
             sort: true,
             customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="flex justify-center text-center">
                            <p>
                                {value[0]}
                            </p>
                            {value[1]?
                            <span class="relative bottom-3 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-gray-600 rounded-full">
                                {value[1] ==="auto"?"A":value[1] === "manual"?"M":null}
                            </span>
                            :
                            null
                            }
                        </div>
                    )
                }
            }
        },
        {
            name: "Id",
            field: "bookId",
            options: {
                filter: false,
                display: false,
            }
        },
        {
            name: "Titre",
            field: "title",
            options: {
            filter: false,
            sort: true,
            }
        },  
     
       {
            name: "Couverture",
            field: "image",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <img src={value} width='50px' alt='couverture' />
                        )
                    }
                }
            },
        {
            name: "Statut tri physique",
            field: "statutTriPhysique",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={classNames(permissionStatus?"cursor-pointer":null,"flex justify-center ")}
                                onClick={()=>{
                                    if(permissionStatus){
                                        // console.log("tri", value, tableMeta)
                                        setSortStatus(value==="accepté"?true:false)
                                        setIsbn(tableMeta.rowData[0][0])
                                        setBookId(tableMeta.rowData[1])
                                        setOpenModalSortStatus(true)
                                    }
                         
                                }}
                            >

                                <span 
                                    className={classNames(
                                                        value === "accepté" ? 'bg-green-100' :  value === "rejeté" ?'bg-red-100': value === "manquant" ?'bg-purple-100':'',
                                                        "px-2 inline-flex text-xs leading-5 font-semibold rounded-full text-green-800"
                                                    )}
                                >
                                    {value}
                                </span>
                            </div>
                        )
                }
            }
        },
        {
            name: "Choix client",
            field: "userChoice",
            options: {
                filter: false,
                sort: true,

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="flex justify-center">
                            <span 
                            className={classNames(
                                                value === "sold" ? 'bg-green-100' : 'bg-purple-100',
                                                "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800"
                                            )}
                            >
                                {value === 'sold'?"Vendu":value === "kept"?"Gardé":value}
                            </span>
                        </div>
                    );
                }
            }
        },
        {
            name: "Prix",
            field: "price",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="flex justify-center">
    
                                {value?value.toString().replace('.',','): null} €
    
                        </div>
                    );
                }
            },
           
        },
       ];


 
 
    const options = {
        rowsPerPageOptions: [10],
        viewColumns: false,
        print:false,
        selectableRowsHideCheckboxes:true,
        textLabels: {
            body: {
            //   noMatch: loader?"En cours de chargement...":"Aucune correspondance...",
              toolTip: "Sort",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
        }
    };

        return (
            <MuiThemeProvider theme={myTheme}>
                <ModalCustom openModal={openModalSortStatus}  modalFunctioToClose={closeModal}
                    form={<FormSortStatus refurbId={props.refurbId} status={sortStatus} isbn={isbn} bookId={bookId} formFunctioToClose={closeModal} ></FormSortStatus>}>
                </ModalCustom>

                <MUIDataTable
                    title={"Ouvrages repris"}
                    data={data}
                    columns={columns}
                    options={options}
                />

            </MuiThemeProvider>

        )
    


    }
// }