import React from 'react';
// import axios from 'axios';
import '../App.css';
import Customers from '../components/customers/allCustomers.js'
import Header from '../components/layout/header'
import Main from '../components/layout/main';

export default function CustomersPage() {
    

        return (
            <>
                <Header title='Clients'/>
                <Main>
                    <Customers></Customers>
                </Main>
            </>
          )
    }
// }