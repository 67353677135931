import React from 'react';
import '../../index.css';
import {Link} from 'react-router-dom'; 

  
export default function Header(props) {
    

        return (

                <header className="bg-white shadow">
                    <div className="container py-6">
                        <h1 className="text-3xl font-bold text-gray-900">{props.title}</h1>
                        {props.navLink?
                            <Link className="text-blue-500 background-transparent text-xs underline" to={props.navLink}>
                                {props.label}
                            </Link>
                        :
                        null
                        }

                    </div>
                </header>
               
          )
    }
