import React from 'react';

import '../App.css';
import AllUsers from '../components/administration/allUsers'
import Header from '../components/layout/header';
import Main from '../components/layout/main';


export default function AdminPage(props) {

    return (
        <>
            <Header title="Administration"/>
            <Main>
                <AllUsers {...props}></AllUsers>
            </Main>  
        </>

     
    )
}
