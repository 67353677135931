import React, {useEffect, useContext} from 'react';
import axios from 'axios';
import { UserContext } from '../../../store/UserContext';


export default function FormSortStatus(props) {
    const [submit, setSubmit] = React.useState(false);
    const [ error, setError ] = React.useState('');
    const { userState } = useContext(UserContext); 
    useEffect(()=>{
        const updateStatusSortRequest = () => {
            if(submit){
                axios({
                    method:'get',
                    url:`${process.env.REACT_APP_ADMIN_URL}/apiFront/refurb/updateRefurbSortStatus/${props.refurbId}/${props.bookId}`,
                    headers: { 'Authorization': 'Bearer ' + userState.user.token},
                })
                .then(function (response) {
                    // console.log("Resp updateStatusSortRequest",response)
                    if(response.data.error){
                        setError(response.data.message)
                    }else{
                        props.formFunctioToClose()
                        // props.modalFunctioToClose()
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
                setSubmit(false)
            }
        }

        if(submit){
            updateStatusSortRequest()
        }
    },[submit, props, userState.user.token])

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setSubmit(true)
    };
  
    return (
        <div className='flex bg-gray-bg1'>
            <div className='w-full max-w-md m-auto bg-white rounded-lg shadow-default py-10'>
                <h1 className='text-xl font-medium text-primary mt-4 mb-12 text-center'>
                  Je souhaite annuler le tri pour cet ouvrage
                </h1>
                {error?<span className="text-xs text-red-500 italic text-center justify-center flex">{error}</span>:null}
                <div className='flex justify-center items-center mt-6'>
                    <button
                        className="bg-green-500 py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark"
                        onClick={handleFormSubmit}
                    >
                        Oui car son statut de tri est incorrect
                    </button>
                    <button
                        className="bg-red-500 py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark"
                        onClick={()=>props.formFunctioToClose()}
                     >
                        Non, finalement j'ai changé d'avis
                    </button>
                </div>
       
            </div>
        </div>
    );
}
