import React, {useEffect, useContext, useRef} from 'react';
import axios from 'axios';
import { UserContext } from '../../../store/UserContext';
import IBAN from 'iban'


export default function FormPwd(props) {
    const [submit, setSubmit] = React.useState(false);
    const [ error, setError ] = React.useState('');
    const [ newIban, setNewIban ] = React.useState({iban: props.iban});
    const { userState } = useContext(UserContext); 
    const refInput = useRef(null)

    useEffect(()=>{
        const updateIbanRequest = () => {
            if(submit){
                axios({
                    method:'post',
                    url:`${process.env.REACT_APP_ADMIN_URL}/apiFront/refurb/updateRefurbIban/${props.refurbId}`,
                    headers: { 'Authorization': 'Bearer ' + userState.user.token},
                    data:newIban,
                })
                .then(function (response) {
                    // console.log("Resp restPwd",response)
                    // console.log("Props rest",props)

                    if(response.data.error){
                        setError(response.data.message)
                    }else{
                        props.dataReturn(response.data.iban)
                        props.formFunctioToClose()
                        // props.modalFunctioToClose()
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
                setSubmit(false)
            }
        }

        if(submit){
            updateIbanRequest()
        }
    },[submit, props, newIban, userState.user.token])


    const handleFormSubmit = (e) => {
        e.preventDefault();
        if(IBAN.isValid(newIban.iban)){
            setError(null)
            setSubmit(true)
        }else{
            setError('Un iban valide est requis')
        }
    };
    return (
        <div className='flex bg-gray-bg1'>
            <div className='w-full max-w-md m-auto bg-white rounded-lg shadow-default py-10'>
                <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center'>
                   Modifier l'iban de l'utilisateur
                </h1>

                <form onSubmit={handleFormSubmit}>
                    <div>
                        <label htmlFor='firstname'>Iban</label>
                        <input
                            ref={refInput}
                            type='text'
                            title="Iban valide requis"
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                            id='newIban'
                            placeholder='Saisir le nouvel iban'
                            value={newIban.iban}
                            onChange={(e)=>setNewIban({iban :e.target.value})}
                        />
                    </div>
                    {error?<span className="text-xs text-red-500 italic">{error}</span>:null}
                    <div className='flex justify-center items-center mt-6'>
                        <button
                            className="bg-green-500 py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark"
                        >
                            Valider
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
