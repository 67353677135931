import React, { useEffect } from 'react';
// import axios from 'axios';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export default function SimpleDialog(props) {

    const [fullWidth] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [form,setForm] = React.useState(props.form);

  

useEffect(()=>{
    setOpen(props.openModal)
},[props.openModal])

useEffect(()=>{
    setForm(props.form)
},[props.form])



const handleClose = () => {
    props.modalFunctioToClose();
    setOpen(false);

};

  return (

        <Dialog fullWidth={fullWidth} aria-labelledby="simple-dialog-title" open={open} onClose={handleClose} style={{width:'100% !important'}}>
            <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                {form}
            </DialogContent>
        </Dialog>

  );
}