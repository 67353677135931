import React, {useEffect, useContext} from 'react';
import axios from 'axios';
import { UserContext } from '../../../store/UserContext';


export default function FormEditUser(props) {
    const { userState } = useContext(UserContext); 
    const [submit, setSubmit] = React.useState(false);
    const [ error, setError ] = React.useState('');
    const [ user, setUser] = React.useState({name: props.user.name,role: props.user.role, email: props.user.email, emailOrigin: props.user.email });

  useEffect(()=>{
        const updateUserRequest = () => {
            if(submit){
                console.log("user", user)
                axios({
                    method:'post',
                    headers: { 'Authorization': 'Bearer ' + userState.user.token},
                    url:`${process.env.REACT_APP_ADMIN_URL}/apiFront/users/updateUser`,
                    data:user,
                })
                .then(function (response) {
                    if(!response.data.error){
                        props.formFunctioToClose();
                        props.dataReturn(response.data.userData)
                    }else{
                        setError(response.data.message)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
                setSubmit(false)
            }
        }

        if(submit){
            updateUserRequest()
        }
    },[submit, props, user,userState.user.token])

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setError(null)
        setSubmit(true)
    };
    return (
        <div className='flex bg-gray-bg1'>
            <div className='w-full max-w-md m-auto bg-white rounded-lg shadow-default py-10'>
                <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center'>
                   Modifier le profil de l'utilisateur
                </h1>

                <form onSubmit={handleFormSubmit}>
                    <div>
                        <label htmlFor='firstname'>Prénom</label>
                        <input
                            type='firstname'
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                            id='firstname'
                            placeholder='Votre prénom'
                            value={user.name}
                            onChange={(e)=>setUser({...user, name:e.target.value})}

                        />
                    </div>
                    <div>
                        <label htmlFor='email'>Email</label>
                        <input
                            type='email'
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                            id='email'
                            placeholder='votre email'
                            value={user.email}
                            onChange={(e)=>setUser({...user, email:e.target.value})}
                        />
                    </div>
                    <div>
                        <label htmlFor='role'>Role</label>
                        <select
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                            onChange={(e)=>setUser({...user, role:e.target.value})}
                        >
                            <option value="operator" selected={user.role==="operator"}>Opérateur</option>
                            <option value="admin" selected={user.role==="admin"}>Administrateur</option>
                            <option value="sav" selected={user.role==="sav"}>Service Client</option>
                            <option value="manager-operator" selected={user.role==="manager-operator"}>Responsable tri</option>
                        </select>
                    </div>
                    {error?<span className="text-xs text-red-500 italic">{error}</span>:null}

                    <div className='flex justify-center items-center mt-6'>
                        <button
                            className="bg-green-500 py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark"
                        >
                            Valider
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
