import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import moment from 'moment'

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
//   }

  export default function TailWindTab(props) {
return (
    <div className="flex flex-col">
      <div  className="text-sm font-medium font-bold text-gray-700 underline">{props.title}</div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {  props.column.map((elt, i)=>{
                      return(
                        <th key={i}
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            {elt}
                        </th>
                      )
                    })}
               {props.buttonLabel==="none"?null:<th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">{props.buttonLabel}</span>
                </th>}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.data.map((elt,i) =>{
                     var totalPrice=0
                     var countBookSold=0
               
                     if(elt.books ){
                        elt.books.forEach(book=>{
                          if(book.userChoice === 'sold'){
                            totalPrice += book.price
                            countBookSold ++
                          }
                        })
                      }

                return (
                  <tr key={i}>
                    
                    {/* <td className="px-6 py-4 whitespace-nowrap">
                      <span 
                        className={classNames(
                                            active ? 'bg-green-100' : '',
                                            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800"
                                        )}
                        >
                            {active?"Actif":"Inactif"}
                      </span>
                    </td> */}
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{i+1}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{elt.mondialRelayExpNb}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{elt.sortedAt?moment(elt.sortedAt).format('DD/MM/YYYY'):(!elt.sortedAt && !elt.active)?"Date non connue":null}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{elt.paidAt?moment(elt.paidAt).format('DD/MM/YYYY'):(!elt.paidAt && elt.customerPaid)?"Date non connue":null}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{countBookSold+" ouvrage"+(countBookSold>1?"s":"")}</div>
                          <div className="text-sm text-gray-500">Pour un total de {totalPrice.toFixed(2)} €</div>
                        </div>
                      </div>
                    </td>
                    {props.buttonLabel==="none"?null:<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                       <Link to={`/Reprise/${elt._id}`}>
                        <button className="text-indigo-600 hover:text-indigo-900">
                                {props.buttonLabel}
                            </button>
                       </Link>
                    </td>}
                  </tr>
                    )
                })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )

    }
// }