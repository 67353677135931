import React, { useState} from 'react';
import { axiosConfig } from '../utils/axiosConfig'
import moment from 'moment'
import { UserContext } from '../store/UserContext';
import { getPermissions } from '../utils/getPermissions';

  export default function Rattrapage(props) {

    const [data, setData] = useState({id:null, amount:0});
    const [ error, setError ] = React.useState('');
    const { userState } = React.useContext(UserContext);
    const permissionRepaid = getPermissions(userState.user.role, props.match.path, "repaid")
    console.log("permissionRepaid",permissionRepaid, props.match.path)


    function downloadBlob(blob) {
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);
      
        // Create a link element
        const link = document.createElement("a");
      
        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        var name = `Bordereau-de-virement-${moment(new Date()).format("YYYY-MM-DD")}`
        link.download = name;
      
        // Append link to the body
        document.body.appendChild(link);
      
        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );
      
        // Remove link from body
        document.body.removeChild(link);
        window.location.reload()
      }   
    const fetchTransferXml = () => {
            axiosConfig.post(`/apiFront/backoffice/api/newXmlGeneration`, data)
            .then(response => {
                //Create a Blob from the PDF Stream
                // console.log('response xml front', response)
                if(response.data.error){
                    alert(response.data.message)
                }else{
                    var file = new Blob([response.data], {type: 'application/xml'});
                    downloadBlob(file)
                }
            })
            .catch(error => {
                setError(error)
                console.log(error);
            });
    } 
 
    const handleFormSubmit = (e) => {
      e.preventDefault();
      if(permissionRepaid){
        fetchTransferXml()
      }else{
        setError("Vous n'avez pas les permissions nécessaires pour effectuer cette opération.")
      }
    };
        return (
          <div className='flex bg-gray-bg1'>
          <div className='w-full max-w-md m-auto bg-white rounded-lg shadow-default py-10'>
              <h1 className='text-xl font-medium text-primary mt-4 mb-12 text-center'>
                 Regénérer un bordereau de virement pour une Reprise {' '}
                 <span className={'text-xs font-bold'}>(la reprise doit être préalablement payée et finalisée)</span>
              </h1>

              <form onSubmit={handleFormSubmit} className='flex flex-col justify-center items-center'>
                  <div className='flex justify-center w-full items-center'>
                    <label className={'m-3'}>ID</label>
                    
                     <input 
                        required
                        className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out`}
                        onChange={(e)=>setData({...data, id: e.target.value})}
                      />
                     <label className={'m-3'}>Montant</label>
                     <input 
                      className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out`}
                      type='number'
                      step="0.01"
                      min="0"
                      required
                      onChange={(e)=>setData({...data, amount: e.target.value})}
                      />
                      

                  </div>
                  {error?<span className="text-xs text-red-500 italic">{error}</span>:null}
                  <div className='flex justify-center items-center mt-6'>
                      <button
                          className="bg-green-500 py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark"
                      >
                          Valider
                      </button>
                  </div>
              </form>
          </div>
      </div>
        )
    }
