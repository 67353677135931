import React from 'react';
import { Button } from '@material-ui/core';
import brokenUrl from '../assets/images/broken-link.png'

function NotFoundPage(props){
    return (
        <div style={{display: "flex", flexDirection: "column", flex:1, textAlign:"center", marginTop: 20}}>
            <div style={{display:'flex', flexDirection: 'column',alignItems: 'center',margin: 50}}>
                <h1>
                        Une erreur s'est produite...
                </h1>
                <h2 >
                    {props.location.state}
                </h2>
                <img src={brokenUrl} alt='icon lien cassé' style={{height:100, width: 100, marginTop: 50}} />

            </div>
         
            <Button
                primary="true"
                onClick={()=>props.history.goBack()}
            >
                Revenir à la page précédente
             </Button>
        </div>
    );
    
}

export default NotFoundPage;