const permissions = {
    "operator":{
        path:   [ '*', "/", "/Reprises","/Reprise/:id"],
        action: [""],
        navigation: ['Dashboard', 'Reprises'],
        profileNav: ['Logout']
    },
    "admin": {
        path:   ["/Reprise/:id","/Rattrapage"],
        action: ["statusDelete","reactivateRefurb","repaid"],
        navigation: ['Dashboard', 'Reprises', 'Clients', "Stats", "Rattrapage",],
        profileNav: ['Administration','Logout']
    },
    "sav":{
        path:   [ '*', "/", "/Reprises","/Reprise/:id", "/Clients", "/Client/:id", "/Rattrapage"],
        action: ["iban", "paiement","statusDelete","reactivateRefurb","repaid"],
        navigation: ['Dashboard', 'Reprises', 'Clients', "Rattrapage"],
        profileNav: ['Logout']
    },
    "manager-operator":{
        path:   [ '*', "/", "/Reprises","/Reprise/:id", "/Clients", "/Client/:id"],
        action: ["statusDelete","reactivateRefurb"],
        navigation: ['Dashboard', 'Reprises', 'Clients'],
        profileNav: ['Logout']
    },
}
export const getPermissions = (role, path, action) =>{
    let allowed
    if(action){
        allowed = permissions[role].path.includes(path) && permissions[role].action.includes(action)
    }else{
        allowed = role?permissions[role].path.includes(path):false
    }

    // console.log("allowed", allowed )

    return allowed
}

export const getNavigation = (role) =>{
    if(role){
        return permissions[role].navigation
    }else{
        return  permissions['admin'].navigation
    }
}
export const getProfilNav = (role) =>{
    if(role){
        return permissions[role].profileNav
    }else{
        return  permissions['admin'].profileNav
    }
}
export const getRoleName = (role) =>{
    let roleName
    switch (role) {
        case "admin":
            roleName = "Administrateur" 
            break;
        case "sav":
            roleName = "Service client" 
            break;
        case "operator":
            roleName = "Opérateur" 
            break;
        case "manager-operator":
            roleName = "Responsable de tri" 
            break;
        
        default:
            break;
    }
    return roleName
}
