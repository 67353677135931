import React, {useEffect, useState, useContext} from 'react';
// import axios from 'axios';
import {axiosConfigEtiquette, axiosConfig} from '../../utils/axiosConfig'
import '../../App.css';
import { UserContext } from '../../store/UserContext';
// import TailWindTab from './tailwindTabRefurbBooks'
import { DocumentTextIcon } from '@heroicons/react/outline'
import DataTableRefurbDetail from './table/dataTableRefurbsDetail';
import { Redirect } from 'react-router-dom';
import ModalCustom from '../modal/modalCustom';
import FormIban from './forms/formIban.js'
import FormPaiementStatus from './forms/formPaimentStatus'
import FormRefurbStatus from './forms/formRefurbStatus'

import { getPermissions } from '../../utils/getPermissions';


  export default function SingleRefurb(props) {
    const { userState } = useContext(UserContext);
    const [data, setData]= useState({books:[],customer:{email:null}, mondialRelayId:"null", mondialRelayExpNb:"null"})
    const [pdfMondialExists, setPdfMondialExists]= useState(false);
    const [pdfRefurbExists, setPdfRefurbExists]= useState(false);
    const [error, setError] = useState({error:0})
    const [openModalIban, setOpenModalIban] = useState(false)
    const [openModalPaimentStatus, setOpenModalPaimentStatus] = useState(false)
    const [openModalRefurbStatus, setOpenModalRefurbStatus] = useState(false)

    const [reload, setReload] = useState(false)
    // console.log("props",props)
    const permissionIban = userState.user.role==="admin"?true:getPermissions(userState.user.role, props.match.path, "iban")
    const permissionPaiement = userState.user.role==="admin"?true:getPermissions(userState.user.role, props.match.path, "paiement")
    const permissionReactivate = userState.user.role==="admin"?true:getPermissions(userState.user.role, props.match.path, "reactivateRefurb")


    console.log("permissionReactivate",permissionReactivate)
 
    const fetchPdfMondial = () =>{
        axiosConfigEtiquette.get(`/apiFront/refurb/pdfMondialRelay/${data.mondialRelayExpNb}`)
            .then(response => {
            //Create a Blob from the PDF Stream
            var file = new Blob([response.data], {type: 'application/pdf'});
            window.open(URL.createObjectURL(file));
            })
            .catch(error => {
                console.log(error);
            });
    }
    const fetchPdfRefurb = () =>{
        axiosConfigEtiquette.get(`/apiFront/refurb/pdfRefurb/${data._id}`)
            .then(response => {
                //Create a Blob from the PDF Stream
                var file = new Blob([response.data], {type: 'application/pdf'});
                window.open(URL.createObjectURL(file));
            })
            .catch(error => {
                console.log(error);
            });
    }

 const loadData = React.useCallback((refurbId)=>{
        axiosConfig.get(`apiFront/backoffice/api/refurbDetail/${refurbId}`)
        .then(function (response) {
            if(!response.data.error){
                setData(response.data.dataRefurbDetail) 
                setPdfMondialExists(response.data.pdfMondialExists)
                setPdfRefurbExists(response.data.pdfRefurbExists)
            }else{
                setError(response.data) 
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    
    setReload(false)
 },[])

    useEffect(()=>{
        loadData(props.match.params.id, reload)
    },[loadData, props.match.params.id, reload]);
    
    const closeModal = ()=>{
        setOpenModalIban(false)
        setOpenModalPaimentStatus(false)
        setOpenModalRefurbStatus(false)

    }
    //Function to update 
    const updateIban = (dataFromBack)=>{
        setData({...data, customer:{...data.customer, iban: dataFromBack}})
    }
    const updatePaiementStatus = (dataFromBack)=>{
        setData({...data, customerPaid:dataFromBack })
    }
    const updateRefurbStatus = (dataFromBack)=>{
        setData({...data, active:dataFromBack })
    }
    const getReloadData=()=>{
        setReload(true)
    }

    if(error.error){
        return <Redirect to={{pathname: '/404', state: error.message }} ></Redirect>
    } else{

            return (
            <div>
                {!pdfMondialExists || !pdfRefurbExists ?
                <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 text-right" role="alert">
                    <p className="font-bold">Information</p>
                    {!pdfMondialExists? 
                        <p className="text-sm">L'étiquette Mondial Relay n'est plus disponible.</p>
                    :null}
                    {!pdfRefurbExists? 
                        <p className="text-sm">L'étiquette de reprise n'est plus disponible.</p>
                    :null}
                </div>
                :null}

                <div className="bg-indigo-50 h-auto pb-10 rounded-xl">
                   
                    <h3 className='px-10 pt-5 text-lg font-semibold'>Informations générales</h3>
                    <div className="flex justify-between items-center px-10">
                        
                        <div>
                            <div className="flex mt-5 ml-5">
                                <h6>mondialRelayExpNb:</h6>
                                <p className="mx-5">{data.mondialRelayExpNb}</p>
                            </div>
                            <div className="flex mt-5 ml-5">
                                <h6>Statut du paiement:</h6>
                                <p className="mx-5">{data.customerPaid?"Payé":"A payer"}</p>
                                
                                {permissionPaiement?
                                <>
                                    <ModalCustom openModal={openModalPaimentStatus}  modalFunctioToClose={closeModal} form={<FormPaiementStatus status={data.customerPaid} refurbId={props.match.params.id} formFunctioToClose={closeModal} dataReturn={updatePaiementStatus}></FormPaiementStatus>}></ModalCustom>
                                    <button  className="cursor-pointer text-center btn border border-black rounded-xl px-2 py-1 text-xs hover:bg-gray-700 hover:text-white "
                                        onClick={()=>{setOpenModalPaimentStatus(true)}}
                                    >
                                        Modifier
                                    </button>
                                </>
                                :
                                null
                                }
                                </div>
                                <div className="flex mt-5 ml-5">
                                    <h6>Statut de la reprise:</h6>
                                    <p className="mx-5">{data.active?"A finaliser":"Finalisée"}</p>
                                    {permissionReactivate?
                                    <>
                                        <ModalCustom openModal={openModalRefurbStatus}  modalFunctioToClose={closeModal} form={<FormRefurbStatus status={data.active} refurbId={props.match.params.id} formFunctioToClose={closeModal} dataReturn={updateRefurbStatus}></FormRefurbStatus>}></ModalCustom>
                                        <button  className="cursor-pointer text-center btn border border-black rounded-xl px-2 py-1 text-xs hover:bg-gray-700 hover:text-white "
                                            onClick={()=>{setOpenModalRefurbStatus(true)}}
                                        >
                                            Modifier
                                        </button>
                                    </>
                                    :
                                    null
                                    }
                            </div>
                        </div>
                        <div className="flex justify-between  ">
                            
                            <div className="flex flex-col">

                                {pdfMondialExists?
                                   
                                        <button onClick={fetchPdfMondial} 
                                            className="min-w-full cursor-pointer text-center btn 
                                            border border-black rounded-xl px-2 py-1 text-xs hover:bg-gray-700 hover:text-white "
                                        >
                                            <div className="flex items-center ">
                                                <DocumentTextIcon className="h-6 w-6"/>
                                                Afficher le bordereau Mondial Relay
                                            </div>
                                        </button>
                                :
                                null}
                                {pdfRefurbExists?
                                    <button onClick={fetchPdfRefurb} className="min-w-full mt-3 cursor-pointer text-center btn border border-black rounded-xl px-2 py-1 text-xs hover:bg-gray-700 hover:text-white ">
                                        <div className="flex items-center">
                                            <DocumentTextIcon className="h-6 w-6"/>
                                            Afficher le bordereau de reprise client
                                        </div>
                                    </button>
                                :
                                null}
                            </div>
                        </div>
                    </div>
                    <div className="px-10 pt-5">
                        <h3 className='text-lg font-semibold'>Client</h3>
                            <div className="flex mt-5 ml-5">
                                <h6>Email:</h6>
                                <p className="mx-5">{data.customer.email}</p>
                            </div>
                            <div className="flex mt-5 ml-5">
                                <h6>Iban:</h6>
                                <p className="mx-5">{data.customer.iban}</p>
                                {permissionIban?
                                    <>
                                        <ModalCustom openModal={openModalIban}  modalFunctioToClose={closeModal} form={<FormIban iban={data.customer.iban} refurbId={props.match.params.id} formFunctioToClose={closeModal} dataReturn={updateIban}></FormIban>}></ModalCustom>
                                        <button  className="cursor-pointer text-center btn border border-black rounded-xl px-2 py-1 text-xs hover:bg-gray-700 hover:text-white "
                                            onClick={()=>{setOpenModalIban(true)}}
                                        >
                                            Modifier
                                        </button>
                                    </>
                                :null    
                                }
                            </div>

                    </div>
                </div>
                <div className="pt-5">
                    <DataTableRefurbDetail getReloadData={getReloadData} data={data.books} refurbId={props.match.params.id}></DataTableRefurbDetail>
                </div>
        </div>
        )
    }

    }
