import React, {useEffect, useState} from 'react';
import {axiosConfig} from '../../utils/axiosConfig'

import '../../App.css';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
 
  
  const myTheme =  createMuiTheme({
    overrides: {
        MuiTableBody: {
        root: {
          cursor:'pointer'
        },
      }
    }
  });

  export default function AllUsers() {
    let history = useHistory();

    const [loader,setLoader] = useState(false);
    const [dataCustomers, setDataCustomers]= useState([])
    const columns  = [
        {
            name: "Prénom",
            field: "firstname",
            options: {
             filter: false,
             sort: true,
            }
           },
        {
         name: "Nom",
         field: "lastname",
         options: {
          filter: false,
          sort: true,
         }
        },
        {
        name: "Email",
        field: "email",
        options: {
            filter: false,
            sort: false,
            }
        },
        {
        name: "Ville",
        field: "city",
        options: {
            filter: false,
            sort: true,
            }
        },
        {
            name: "Numéro de téléphone",
            field: "phone",
            options: {
                filter: false,
                sort: true,
                }
            }
       ];
    
    useEffect(()=>{
        setLoader(true)
        axiosConfig.get('apiFront/backoffice/api/customers')
        // axios({
        //     method:'get',
        //     headers: { 'Authorization': 'Bearer ' + userState.user.token},
        //     url:`${process.env.REACT_APP_ADMIN_URL}/backoffice/api/customers`,
        // })
        .then(function (response) {
            // console.log("RESPONSE",response.data)
            setDataCustomers(response.data.dataCustomers)
        })
        .catch(function (error) {
            console.log(error);
        });
        setLoader(false)
    },[]);

    const rowClicked = (rowData, rowMeta, event)=>{
        if(event.ctrlKey){
            setTimeout(() => window.open(`/Client/${dataCustomers[rowMeta.dataIndex][5]}`), 5);
        }else{
            history.push(`/Client/${dataCustomers[rowMeta.dataIndex][5]}`,null);

        }
    //    setSelectedCustomer(dataCustomers[rowMeta.dataIndex][4])
    //    return <Redirect to={`/Client/${selectedCustomer}`}></Redirect>
    };

 
    const options = {
        customToolbarSelect: () => {},
        rowsPerPageOptions: [10],
        viewColumns: false,
        print:false,
        // selectableRows:"single",
        selectableRowsHideCheckboxes:true,
        // selectableRowsOnClick:true,
        onRowClick : rowClicked,
        textLabels: {
            body: {
              noMatch: loader?"En cours de chargement...":"Aucune correspondance...",
              toolTip: "Sort",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
        }
    };

        return (
            <MuiThemeProvider theme={myTheme}>
                <MUIDataTable
                    title={"Liste des clients"}
                    data={dataCustomers}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>

        )

    }
// }