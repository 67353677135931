import React from 'react';
// import axios from 'axios';
import '../index.css';
import CardStats from '../components/dashboard/cardStats'
import KpiByDate from '../components/dashboard/KpiByDate'
import Header from '../components/layout/header';
import Main from '../components/layout/main';

export default function Dashboard() {
    

        return (
            <>
                <Header title='Dashboard' />
                <Main>
                    <div style={{display:"flex", flexDirection:'column',justifyContent:'center'}}>
                        <CardStats/>
                    </div>
                    <div className="mt-10 h-full">
                        <KpiByDate></KpiByDate>
                    </div>
                </Main>
            </>
          )
    }
// }