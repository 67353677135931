import React, {useEffect, useState} from 'react';
import { axiosConfig, axiosConfigEtiquette } from '../../../utils/axiosConfig'
import Alert from '@mui/material/Alert';
import { useHistory } from 'react-router-dom';
import '../../../App.css';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment'
import Snackbar from '@mui/material/Snackbar';
import {CopyToClipboard} from 'react-copy-to-clipboard';

//NB : dataRefurbs est tableau de valeurs, l'accès au data se fait par index. Lorsqu'une data est ajoutée à ce tableau, l'index des data est modifiée.
//cela impacte plusieurs fonction : isRowSelectable, onRowSelectionChange, cellClicked. Il faut donc penser à les mettre à jour à chaque ajout ou modification d'ordonnancement des données du tableau

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
const myTheme =  createMuiTheme({

    overrides: {
        MuiTableBody: {
        root: {
          cursor:'pointer'
        },
      }
    }
  });

  export default function AllRefurbs() {
    let history = useHistory();
    const [loader,setLoader] = useState(false);
    const [dataRefurbs, setDataRefurbs]= useState([])
    const [copy, setCopy] = useState(false);
    const [idXmlGeneration, setIdXmlGeneration] = useState([]);
    const [selectionTransferAmount, setSelectionTransferAmount] = useState(0);
    const [selectedRows, setSelectedRows] = React.useState([])

    useEffect(()=>{
        setLoader(true)
        
        axiosConfig.post('apiFront/backoffice/api/refurbs/pagination',
        {"filterRefurbStatus":"A payer"}
        )
        .then(function (response) {
            // console.log("RESPONSE",response.data)
            setDataRefurbs(response.data.dataRefurbs)
            setLoader(false)
        })
        .catch(function (error) {
            console.log(error);
            setLoader(false)
        });
    },[]);
   
    const onRowSelectionChange = React.useCallback((currentRowsSelected, allRowsSelected, rowsSelected) => { 
        setSelectedRows(rowsSelected)
        var priceTransfer = 0 

        let idsRefurbs = rowsSelected.map(index => {
            var price = parseFloat(dataRefurbs[index][5][1].replace(',','.'))
            priceTransfer = price + priceTransfer
            return dataRefurbs[index][0]
        })
        setSelectionTransferAmount(priceTransfer.toFixed(2).toString().replace('.',','))
        setIdXmlGeneration(idsRefurbs)
    }, [dataRefurbs]);

    useEffect(() => {
        var selectedRowsIndex = dataRefurbs.map((elt,i)=>{
            return i
        })
        onRowSelectionChange(null,null,selectedRowsIndex)
      }, [dataRefurbs,onRowSelectionChange])

     
    
        

    const columns  = [
        {
            name: "ID reprise",
            field: "_id",
            options: {
             filter: false,
             sort: true,
        
             customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <div className="flex flex-row items-center space-x-2 justify-between">
                        <p  
                            onClick={(e)=> navigateToRefurbDetails(e.ctrlKey, value)}
                        >
                           {value.substring(0, 8)} 
                        </p>
                        <CopyToClipboard
                            text={value}
                            onCopy={()=>{
                                setCopy(true)
                                setTimeout(()=>{
                                    setCopy(false)
                                },2000)}
                            }
                        >
                        <p 
                            data-clipboard-target="#idRefurb"
                            className="btn border border-black rounded-xl ml-auto p-1 text-xs hover:bg-gray-700 hover:text-white "
                        >
                            Copier
                        </p>
                        </CopyToClipboard>
                    </div>
                
                )
            }
            }
           },
        {
         name: "N° d'expédition Mondial Relay - tracing",
         field: "mondialRelayExpNb",
         options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <div   className="flex justify-center items-center">
                    <p>{value[0]} / {value[1]}</p>
                </div>
            );
        }
         }
        },
       {
        name: "Date de création",
        field: "createdAt",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                var date = value.split("").splice(0,10).join("").split("-").reverse().join("/")
                    return (
                        <p>{date}</p>
                    )
                },
            },
        },
        {
        name: "Client",
        field: "customer",
        options: {
            filter: false,
            sort: true,
            }
        },
        {
            name: "Nb d'ouvrages",
            // field: "bookSold",
            options: {
                filter: false,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{width:100}} className="flex flex-col justify-center items-start">
                            <p>Attendu: {value[0]}</p>
                            <p>Accepté: {value[1]}</p>
                            <p>Rejeté: {value[2]}</p>
                        </div>
                    );
                }
            }
        },
        {
            name: "Total reprise en €",
            // field: "bookSold",
            options: {
                filter: false,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div  style={{width:100}} className="flex flex-col justify-center items-start">
                            <p>Estimé: {value[0]} €</p>
                            <p>Réel: {value[1]} €</p>
                        </div>
                    );
                }
            }
        },
        {
            name: "Statut reprise",
            field: "customerPaid",
            options: {
                filter: false,
                sort: true,

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        value?
                        <div className="flex justify-center text-center">
                            <span 
                                className={classNames(value==="A payer"?"bg-red-600":value==="Payé"?"bg-green-600":"bg-purple-600",
                                            "inline-flex px-3 py-2 text-xs font-bold leading-none text-white rounded-full w-max")}
                            >
                                {value}
                            </span>
                        </div>
                        :
                        null
                    );
                }
            }
        },
        {
        name: "Bon de reprise",
        field: "refurbPdf",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <button className="inline-flex items-center bg-transparent text-black text-opacity-70 font-semibold hover:bg-black hover:bg-opacity-70 hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded">
                        <span className=" ">Bordereau de reprise</span>
                        <svg className="fill-current w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                  </button>
                );
              }
            }
        }
       ];
       const fetchPdfRefurb = (id) =>{
        axiosConfigEtiquette.get(`/apiFront/refurb/pdfRefurb/${id}`)
            .then(response => {
                //Create a Blob from the PDF Stream
                var file = new Blob([response.data], {type: 'application/pdf'});
                window.open(URL.createObjectURL(file));
            })
            .catch(error => {
                console.log(error);
            });
    }
    const navigateToRefurbDetails = (ctrlKey, id) => {
        
        if(ctrlKey){
            setTimeout(() => window.open(`/Reprise/${id}`), 5);
        }else{
            history.push(`/Reprise/${id}`,null);
        }
    }
    function downloadBlob(blob) {
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);
      
        // Create a link element
        const link = document.createElement("a");
      
        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        var name = `Bordereau-de-virement-${moment(new Date()).format("YYYY-MM-DD")}`
        link.download = name;
      
        // Append link to the body
        document.body.appendChild(link);
      
        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );
      
        // Remove link from body
        document.body.removeChild(link);
        window.location.reload()
      }   
    const fetchTransferXml = () => {
            axiosConfig.post(`/apiFront/backoffice/api/transferXml`, {data: idXmlGeneration})
            .then(response => {
                //Create a Blob from the PDF Stream
                // console.log('response xml front', response)
                if(response.data.error){
                    alert(response.data.message)
                }else{
                    var file = new Blob([response.data], {type: 'application/xml'});
                    downloadBlob(file)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const cellClicked = (colData, cellMeta)=>{
        // console.log("event dataTable", cellMeta)
        if(cellMeta.colIndex === 7){
            fetchPdfRefurb(dataRefurbs[cellMeta.dataIndex][0])
        }else if (cellMeta.colIndex !== 0){
            navigateToRefurbDetails(cellMeta.event.ctrlKey, dataRefurbs[cellMeta.dataIndex][0])
        }
           
     };

    
 
    const options = {
        rowsPerPageOptions: [10],
        filter:false,
        viewColumns: false,
        print:false,
        rowsSelected: selectedRows,
        isRowSelectable: function(dataIndex, selectedRows, data){
            if(dataRefurbs[dataIndex][6] === "A payer"){
                return true
            }else{
                return false
            }
        },
        selectableRowsHeader: true,
        onRowSelectionChange,
        onCellClick: cellClicked,
        customToolbarSelect: selectedRows => (
            <div className="flex  my-5 justify-end mr-5 w-3/4">
                <p className="mr-5">
                    Montant total: {selectionTransferAmount} €
                </p>
                <span className="relative inline-block cursor-pointer"
                    onClick={()=>{
                        // console.log("countTransfer, idXmlGeneration",selectionTransferAmount, idXmlGeneration)
                        if(selectionTransferAmount && idXmlGeneration.length>0){
                            fetchTransferXml()
                        }else{
                            alert('Vous devez impérativement sélectionner une ou plusieurs reprises éligibles')
                        }
                    }}
                >
                    <span className="w-6 h-6 text-gray-700 fill-current ursor-pointer text-center btn border border-black rounded-xl ml-auto p-2 text-xs hover:bg-gray-700 hover:text-white " viewBox="0 0 20 20">
                        Générer le bordereau de virement
                    </span>
                </span>
                </div>         
          ),
        onRowsDelete: false,
        textLabels: {
            body: {
              noMatch: loader?"En cours de chargement...":"Aucune correspondance...",
              toolTip: "Sort",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            selectedRows: {
                text: ["reprise(s) sélectionnée(s)"],
            },
        }
    };

        return (
            <MuiThemeProvider theme={myTheme}>
                <Snackbar open={copy} autoHideDuration={2000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        ID de reprise copié :)
                    </Alert>
                </Snackbar>
                <MUIDataTable
                    title={"Liste des reprises à payer"}
                    data={dataRefurbs}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        )
    }
