import React, {useEffect, useState, useContext} from 'react';
import axios from 'axios';
import '../../App.css';
import { UserContext } from '../../store/UserContext';
import {Link} from 'react-router-dom'; 
import {PlusCircleIcon} from '@heroicons/react/outline'
import FormUserCreation from './forms/formUserCreation'
import ModalCustom from '../modal/modalCustom'
import { getRoleName } from '../../utils/getPermissions';


// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
//   }

  export default function AllUsers() {
    const { userState } = useContext(UserContext); 
    const [dataUsers, setDataUsers]= useState([{name:""}])
    const [openModal, setOpenModal]=useState(false)
    
    useEffect(()=>{
        axios({
            method:'get',
            headers: { 'Authorization': 'Bearer ' + userState.user.token},
            url:`${process.env.REACT_APP_ADMIN_URL}/apiFront/users/getUsers`,
        })
        .then(function (response) {
            // console.log("RESPONSE",response.data)
            setDataUsers(response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
    },[userState.user.token,openModal])
    
    const closeModal = ()=>{
        setOpenModal(false)
    }

return (
    <div className="flex flex-col">
        <div className="flex justify-between mb-5">
            <div  className="text-sm font-medium text-gray-700 underline">UTILISATEURS:</div>
            <PlusCircleIcon className="w-8 text-blue-500"
            onClick={()=>{setOpenModal(true)}}
            />
        </div>
        
    <ModalCustom openModal={openModal} modalFunctioToClose={closeModal} form={<FormUserCreation formFunctioToClose={closeModal} />}></ModalCustom>

      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th> */}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Role 
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {dataUsers.map((person,i)=>{
                    //  var active = person.name===('remi')?true:false;
                    //  console.log("Test user active",active);
                return (
                  <tr key={i}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {/* <div className="flex-shrink-0 h-10 w-10">
                          <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                        </div> */}
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{person.name}</div>
                          <div className="text-sm text-gray-500">{person.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {/* <div className="text-sm text-gray-900">Développeur</div> */}
                      <div className="text-sm text-gray-500">Ammareal</div>
                    </td>
                    {/* <td className="px-6 py-4 whitespace-nowrap">
                      <span 
                    //   className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800">
                        className={classNames(
                                            active ? 'bg-green-100' : '',
                                            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800"
                                        )}
                        >
                            {active?"Actif":"Inactif"}
                      </span>
                    </td> */}
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{getRoleName(person.role)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link to={`/Admin/${person._id}`}>
                        <button className="text-indigo-600 hover:text-indigo-900">
                            Edit
                        </button>
                      </Link>
                    </td>
                  </tr>
                    )
                })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )

    }
// }