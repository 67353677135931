import React, {useEffect, useState} from 'react';
// import axios from 'axios';
import {axiosConfig} from '../../utils/axiosConfig'
import moment from 'moment';
import { ResponsiveLine } from '@nivo/line'
import CardCustom from '../cardCustom/cardCustom';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const dataMock = [
    {
      "id": "Global",
      "color": "#f768a1",
      "data": [
        {
            "x": "MockedData 24/3",
            "y": 2
        },
        {
            "x": "MockedData 3/5",
            "y": 19
        },
        {
            "x": "MockedData 23/3",
            "y": 2
        }
      ]
    }
  ]
  export default function MyResponsiveLine () {
    var dateNow = moment().format("YYYY-MM-DD")

    const [data, setData] = useState(dataMock)
    const [startDate, setStartDate] = useState("2022-01-01")
    const [endDate, setEndDate] = useState(dateNow)
    const [refurbTotalBetweenDate, setRefurbTotalBetweenDate] = useState(0)
    const [booksBeetweenDate, setBooksBetweenDate] = useState(0)
    const [displayDate, setDisplayDate]= React.useState([startDate, dateNow])

    useEffect(()=>{ 
        const fetchData=()=>{
            // console.log('date move',startDate, endDate )
            axiosConfig.post('apiFront/backoffice/api/kpiByDate',
                { startDate, endDate }
            )
            .then(function (response) {
                // console.log("RESPONSE",response.data)
                var freshData = [...dataMock]
                freshData[0]["data"]=response.data.data
                setData(freshData)
               var somme = response.data.data.reduce(function (accumulateur, valeurCourante) {
                        return accumulateur + valeurCourante.y;
                    }, 0)
                setRefurbTotalBetweenDate(somme)
                setBooksBetweenDate(response.data.books[0])
            })
            .catch(function (error) {
                console.log(error);
            });
        }
       fetchData()
    },[startDate, endDate])
// console.log("Data",data)

const setDateValue = (value, which) => {

    if(which ==='start'){
        let date = moment(value,'YYYY-MM-DD', true)
        setDisplayDate([value, endDate])

        if( (date.isBefore(endDate) || date.isSame(endDate) ) && date.isAfter('2021-01-01')){
            setStartDate(value)
        }else{
            console.log("startDate > EndDate!!")
        }
    }else{
    
        let date = moment(value,'YYYY-MM-DD', true)
        setDisplayDate([startDate, value])

        if( (date.isAfter(startDate) || date.isSame(startDate)) && date.isAfter('2021-01-01') && date.isBefore(dateNow) ){
            setEndDate(value)
        }else{
            console.log("startDate > EndDate!!")
            // setDisplayDate([startDate, endDate])
        }
    }

  
}

const SvgReprise = ()=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  className="fill-current w-7 h-7" width="24" height="24" viewBox="0 0 24 24">
            <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
        </svg>
    )

}
      return (
        <div className="w-auto h-500 my-10"> 
            <p className="font-bold underline">Historique des reprises</p>
            
            <p className=' mt-3 mb-1'>Sélecteur de dates : </p>
            <div date-rangepicker='true' className="flex items-center">
                
                <div className="relative">
                        <input name="start" type="date" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Sélection date de début"
                            onChange={e=>{setDateValue(e.target.value, "start") }}
                            value={displayDate[0]}
                            max={dateNow}
                        />
                    </div>
                <span className="mx-4 text-gray-500">à</span>
                <div className="relative">
                    <input name="end" type="date" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Sélection date de fin"
                     onChange={e=>{setDateValue(e.target.value, "end") }}
                      value={displayDate[1]}
                      max={dateNow}
                    />
                </div>
            </div>
            <div className='flex flex-col w-full h-full items-start justify-between mt-3'>
                <CardCustom title="Nombre totale de reprises" mainValue={refurbTotalBetweenDate} link={false} icon={<SvgReprise/>}
                     value1={["Ouvrages acceptés au tri", booksBeetweenDate.sumBooks]}
                     value2={["Montant", booksBeetweenDate.totalPrice]}
                />
                <ResponsiveLine 
                    tooltip = {(input)=>{
                        return(
                        <div className='block p-3 rounded-md shadow-lg bg-gray-500'>
                            <p className='text-white font-bold'>
                                {input.point.data.xFormatted}: {parseInt(input.point.data.yFormatted)} {input.point.data.yFormatted>1?" reprises":" reprise"}
                            </p>
                        </div>
                        )
                    }
                    }
                    colors={{ scheme: 'accent' }}
                    data={data}
                    margin={{ top: 50, right: 110, bottom: 60, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: '0', max: 'auto', stacked: true, reverse: false }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 7,
                        tickPadding: 10,
                        tickRotation: -42,
                        legend: '',
                        legendOffset: 50,
                        legendPosition: 'end'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Total reprises',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
        </div>

  )
}