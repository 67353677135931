import React from 'react';
import './index.css';
import Layout from './components/layout/layout'
import packageJson from "../package.json";
import moment from "moment";

function App() {
    
    const refreshCacheAndReload = () => {
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then((names) => {
            for (const name of names) {
              caches.delete(name);
            }
          });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
    };

    const buildDateGreaterThan = (latestDate, currentDate) => {
        const momLatestDateTime = moment(latestDate);
        const momCurrentDateTime = moment(currentDate);
        
        if (momLatestDateTime.isAfter(momCurrentDateTime)) {
            return true;
        } else {
            return false;
        }
    };

    React.useEffect(() => {
        fetch("/meta.json")
          .then((response) => response.json())
          .then((meta) => {
            // console.log("meta",meta)
    
            const latestVersionDate = meta.buildDate;
            const currentVersionDate = packageJson.buildDate;
            console.log("latestVersionDate",latestVersionDate)
            console.log("currentVersionDate",currentVersionDate)

            const shouldForceRefresh = buildDateGreaterThan(
              latestVersionDate,
              currentVersionDate
            );
            if (shouldForceRefresh) {
              console.log("refresh forced")
              // setIsLatestBuildDate(false);
              refreshCacheAndReload();
            } 
          });
      }, []);

    return(
            <Layout />
    )
}

export default App;
