import React from 'react';
import {BrowserRouter, Switch, Route } from 'react-router-dom'; 

import PrivateRoute from '../../auth/PrivateRoute'

//Views
import Dashboard from '../../views/Dashboard.js';
import CustomersPage from '../../views/CustomersPage.js'
import CustomerDetail from '../../views/CustomerDetail'
import RefurbsPage from '../../views/RefurbsPage.js'
import RefurbDetail from '../../views/RefurbDetail'
import AdminPage from '../../views/AdminPage'
import AdminDetail from '../../views/AdminDetail'
import LoginPage from '../../views/LoginPage'
import NotFoundPage from '../../views/PageNotFound';
import UnauthorizedPage from '../../views/UnauthorizedPage';
import BooksStatsPage from '../../views/BooksStatsPage'
import Rattrapage from '../../views/Rattrapage';

 function Layout(props) {

        // console.log("Layout props",props)
return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={LoginPage}  />
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute exact path="/Client/:id" component={CustomerDetail}  />
                <PrivateRoute exact path="/Clients" component={CustomersPage}  />
                <PrivateRoute exact path="/Reprises" component={RefurbsPage} />
                <PrivateRoute exact path="/Stats" component={BooksStatsPage} />
                <PrivateRoute exact path="/Reprise/:id" component={RefurbDetail} />
                <PrivateRoute exact path="/Rattrapage" component={Rattrapage} />

                <PrivateRoute exact path="/Admin/:id" component={AdminDetail} />
                <PrivateRoute exact path="/Administration" component={AdminPage}/>
                {/* <Route exact path='/Public' component={PublicTestPage}/> */}
                <Route path="/unauthorized" component={UnauthorizedPage}/>
                <PrivateRoute path="/404" component={NotFoundPage}/>
                <PrivateRoute path="*" component={NotFoundPage}/>

            </Switch>
                        
        </BrowserRouter>
    )
}
export default Layout;