import React from 'react';
import { Route, Redirect } from "react-router-dom";

import { UserContext } from '../store/UserContext'
import Navbar from '../components/layout/navbar'
import {getPermissions} from '../utils/getPermissions'

export default function PrivateRoute({ ...children}) {
   
    const { userState } = React.useContext(UserContext);
    // console.log("children", children)

    const permission = userState.user.role==="admin"?true:getPermissions(userState.user.role, children.path)
    // console.log("props", props)
    if(userState.loggedIn && permission){
        // console.log("PrivateRoute logged true")
        return(
            <>
                <Navbar page={children}></Navbar>
                <Route
                    {...children}
                />
            </>
        )
    }else if(userState.loggedIn && !permission){
        return(
            <Redirect
                to={'/unauthorized'}
            />
        )
    }else{  
        // console.log("PrivateRoute logged false")
        return(
            <Redirect
                to={'/login'}
            />
        )
       
    }
  }