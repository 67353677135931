import React, {useEffect, useState} from 'react';
import {axiosConfig} from '../../utils/axiosConfig'
import '../../App.css';
import TailWindTab from './tailWindTab'

  export default function SingleCustomer(props) {
    const [dataCustomer, setDataCustomer]= useState({firstName:"",refurbs:[]})
    useEffect(()=>{
        axiosConfig.get(`apiFront/backoffice/api/customerDetail/${props.match.params.id}`)

        // axios({
        //     method:'get',
        //     headers: { 'Authorization': 'Bearer ' + userState.user.token},
        //     url:`${process.env.REACT_APP_ADMIN_URL}/backoffice/api/customerDetail/${props.match.params.id}`,
        // })
        .then(function (response) {
            // console.log("RESPONSE",response.data)
            setDataCustomer(response.data.dataCustomer)
        })
        .catch(function (error) {
            console.log(error);
        });
    },[props.match.params]);

return (
    <div>
        <div className="bg-indigo-50 h-auto pb-10 rounded-xl">
            <div className="px-10 pt-5">
                <h3 className='text-lg font-semibold'>Informations générales</h3>
                    <div className="flex mt-5 ml-5">
                        <h6>Prénom:</h6>
                        <p className="mx-5">{dataCustomer.firstName}</p>
                    </div>
                    <div className="flex mt-5 ml-5">
                        <h6>Nom:</h6>
                        <p className="mx-5">{dataCustomer.lastName}</p>
                    </div>
            </div>
            <div className="px-10 pt-5">
                <h3 className='text-lg font-semibold'>Adresse</h3>
                    <div className="flex mt-5 ml-5">
                        <h6>Adresse:</h6>
                        <p className="mx-5">{dataCustomer.address1}</p>
                    </div>
                    <div className="flex mt-5 ml-5">
                        <h6>Code postal:</h6>
                        <p className="mx-5">{dataCustomer.postCode}</p>
                    </div>
                    <div className="flex mt-5 ml-5">
                        <h6>Ville: </h6>
                        <p className="mx-5">{dataCustomer.city}</p>
                    </div>
                    <div className="flex mt-5 ml-5">
                        <h6>Numéro de téléphone: </h6>
                        <p className="mx-5">{dataCustomer.phone}</p>
                    </div>
            </div>
        </div>
        <div className="pt-5">
            <TailWindTab title="Reprises" column={["N°","Mondial Relay Expnb","Date de tri", "Date de paiement","Récapitulatif de la reprise"]} buttonLabel="Voir" data={dataCustomer.refurbs}></TailWindTab>
        </div>
   </div>
  )

    }
