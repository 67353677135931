import React from 'react';
// import axios from 'axios';
import '../App.css';
import Customer from '../components/customers/singleCustomer'
import Header from '../components/layout/header';
import Main from '../components/layout/main';

export default function CustomerPage(props) {
    
        return (
            <>
            <Header title='Client'></Header>
            <Main>
                <Customer {...props}></Customer>
            </Main>
            </>
          )
    }
// }