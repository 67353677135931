import { useContext} from 'react'
import axios from "axios";
import {UserContext} from '../store/UserContext';

const axiosConfig = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_URL}/`,
});

//Used in the single refurb component
const axiosConfigEtiquette = axios.create({
    baseURL: `${process.env.REACT_APP_ADMIN_URL}/`,
  });
axiosConfigEtiquette.defaults.responseType = 'blob'
axiosConfigEtiquette.defaults.headers['Authorization']= `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`;

export default function Interceptor({children}){
    const {userDispatch} = useContext(UserContext)
    const {userState} = useContext(UserContext)
    var token = userState.user.token


    axiosConfig.defaults.headers["Authorization"] = 'Bearer ' + token;

        axiosConfig.interceptors.response.use(response =>{
            // console.log(" AXIOS intercept response", response);
            let globalresponse = {data:response.data}
            return globalresponse;
        }, async (error) => {
            console.log("responseAXIOS !!", error.response.status);
            if( error.response.status===401){
            console.log("AXIOSError!! dispatch", error.response.status);
                userDispatch({type:'logout'})
            }
        })
   
       
    return children

}

export { axiosConfig, axiosConfigEtiquette}
// export default axiosConfig;