import React, {useEffect} from 'react';
import axios from 'axios';
// import {AxiosConfig} from '../utils/axiosConfig'
import '../App.css';
import { LockClosedIcon } from '@heroicons/react/solid'
import { Redirect } from 'react-router-dom';


// //Store
import { UserContext } from '../store/UserContext'
  
  

  export default function Login() {
    // let history = useHistory();
    // let location = useLocation();
    const { userDispatch,userState } = React.useContext(UserContext);
    // const { userState } = React.useContext(UserContext);
   
    const [ error, setError ] = React.useState();
    const [ message, setMessage ] = React.useState('');
    const [ user, setUser ] = React.useState({email: '',password: ''});
    const [submit, setSubmit] = React.useState(false);
    const [forget] = React.useState(false);


useEffect(()=>{
     
    const cleanMessage=()=>{
        setError("");
        setMessage("")
    }
    const signIn = () => {
        cleanMessage()
        
        if(!forget){
            axios.post(`${process.env.REACT_APP_ADMIN_URL}/apiFront/users/login`,user,
               { headers: { 
                   'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}` 
                },
             })
            .then(function (response) {
                setSubmit(false)

                if(!response.data.error){
                    
                    userDispatch({type:'login', payload: response.data.user}) 
                }else{
                    setError(response.data.message)
                }
            })
            .catch(function (err) {
                setSubmit(false)
                console.log("error!!",err);
            });
        }else{
            // axios({
            //     method:'post',
            //     url:`${process.env.REACT_APP_ADMIN_URL}/users/temporaryPassword`,
            //     data:user,
            //     withCredentials: true,  
            // })
            // .then(function (response) {
            //     if(!response.data.error){
            //         // console.log('response forget',response.data)
            //         setMessage(response.data.message) 
            //     }else{
            //         // console.log('response forget',response.data)
            //         setError(response.data.message)
            //     }
            // })
            // .catch(function (error) {
            //     console.log(error);
            // });
            // setSubmit(false)
        }
    }

    if(submit){
        signIn()
    }
},[submit, user,forget,setError,userDispatch,setMessage])

    // if(userState.user.updatePwd && !userState.loggin){
    //     return(<div>No login</div>)
    //     // return (<Redirect to={`/forgetPwd`}></Redirect>)
    // }else{
        if(userState.loggedIn){
            return (<Redirect to={`/`}></Redirect>)
        }else{

            return (
                <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                    <img
                        className="mx-auto h-12 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                        alt="Workflow"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">S'identifier</h2>
                    </div>
                    <form className="mt-8 space-y-6"   onSubmit={ (e) => {
                        e.preventDefault();
                            setSubmit(true)
                        }  
                        }>
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                        <label htmlFor="email-address" className="sr-only">
                        adresse mail
                        </label>
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Adresse mail"
                            onChange={(e)=>{setUser({...user,email:e.target.value})}}
                        />
                        </div>
                        <div>
                        <label htmlFor="password" className="sr-only">
                            Mot de passe
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Mot de passe"
                            onChange={(e)=>{setUser({...user,password:e.target.value})}}
                        />
                        </div>
                            {error?<p style={{color:"red",fontSize:12,fontStyle:'italic'}}>{error}</p>:null}
                        </div>
                    <div>
                        
                        <button
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                        </span>
                        Valider
                        </button>
                    </div>
                    {message?<p>{message}</p>:null}
                    </form>
                </div>
                </div>
            )
        }
    }
// }