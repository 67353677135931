import React, {useEffect, useState} from 'react';
// import axios from 'axios';
import {axiosConfig} from '../../utils/axiosConfig'

import { ResponsiveBar } from '@nivo/bar'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const dataMock = 
    [
        {
          "Jour": "1",
          "sold": 3,
        //   "soldColor": "hsl(18, 70%, 50%)",
          "kept": 5,
        //   "keptColor": "hsl(335, 70%, 50%)",
          "rejected": 12,
        //   "rejectedColor": "hsl(183, 70%, 50%)"
        },
        {
          "Jour": "2",
          "sold": 5,
        //   "soldColor": "hsl(18, 70%, 50%)",
          "kept": 7,
        //   "keptColor": "hsl(335, 70%, 50%)",
          "rejected": 8,
        //   "rejectedColor": "hsl(183, 70%, 50%)"
        },
    ]
      
  export default function MyResponsiveBar () {
    const [data, setData]= useState(dataMock)
    // console.log('data',data)
    useEffect(()=>{
        axiosConfig.get('apiFront/backoffice/api/booksStats')
 
        .then(function (response) {
            setData(response.data.statsBooks)
        })
        .catch(function (error) {
            console.log(error);
        });
    },[])
      return (
        <div
            className="w-auto h-500 mt-10"
        > 
            <p className="font-bold underline">Statut des ouvrages par utilisateurs </p>
            <p>Données des dernières 24h</p>
            <ResponsiveBar
                data={data}
                keys={[ 'sold', 'kept', 'rejected']}
                indexBy="device_id"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                // colors={{ scheme: 'nivo' }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                // fill={[
                //     {
                //         match: {
                //             id: 'fries'
                //         },
                //         id: 'dots'
                //     },
                //     {
                //         match: {
                //             id: 'sandwich'
                //         },
                //         id: 'lines'
                //     }
                // ]}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -50,
                    // legend: 'Device_id',
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Choix utilisateur',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                // barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
            />
        </div>
  )
}