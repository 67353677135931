import React, {useEffect, useContext} from 'react';
import axios from 'axios';
import { UserContext } from '../../../store/UserContext';


export default function FormPwd(props) {
    const [submit, setSubmit] = React.useState(false);
    const [ error, setError ] = React.useState('');
    const [ status, setStatus ] = React.useState(props.status);
    const { userState } = useContext(UserContext); 

    useEffect(()=>{
        const updateIbanRequest = () => {
            if(submit && (status !== "null" || status !== "undefined")){
                axios({
                    method:'get',
                    url:`${process.env.REACT_APP_ADMIN_URL}/apiFront/refurb/updateRefurbPaiementStatus/${props.refurbId}/${status}`,
                    headers: { 'Authorization': 'Bearer ' + userState.user.token},
                })
                .then(function (response) {
                    // console.log("Resp restPwd",response)
                    // console.log("Props rest",props)

                    if(response.data.error){
                        setError(response.data.message)
                    }else{
                        props.dataReturn(response.data.customerPaid)
                        props.formFunctioToClose()

                        // props.modalFunctioToClose()
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
                setSubmit(false)
            }
        }

        if(submit){
            updateIbanRequest()
        }
    },[submit, props, status, userState.user.token])

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setSubmit(true)
    };
    function Switch() {
        // const [toggle, setToggle] = React.useState(status);
        const toggleClass = " transform translate-x-6";
        return (
            <div className="flex w-1/2 justify-between items-center ">
            
            <label>Payé</label>
            <div className="flex flex-col justify-center items-center ">
              {/*   Switch Container */}
              <div
                className={"md:w-14 md:h-7 w-12 h-6 flex items-center  rounded-full p-1 cursor-pointer" + (status ? " bg-green-400" : " bg-red-400") }
                onClick={() => {
                  setStatus(!status);
                }}
              >
                {/* Switch */}
                <div
                  className={ (status ? " bg-green-900" : " bg-red-900") +
                    " bg-black md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out" +
                    (status ? null : toggleClass)
                  }
                ></div>
              </div>
            </div>
            <label>A payer</label>
            </div>
        );
      }
    return (
        <div className='flex bg-gray-bg1'>
            <div className='w-full max-w-md m-auto bg-white rounded-lg shadow-default py-10'>
                <h1 className='text-xl font-medium text-primary mt-4 mb-12 text-center'>
                   Modifier le statut de paiement : <span className='italic' >{props.status?"Payé":"A payer"}</span>
                </h1>

                <form onSubmit={handleFormSubmit}>
                    <div className='flex justify-center'>
                        {/* <label htmlFor='firstname'>Statut du paiement: </label>
                        <select name="status" id="status-select"
                            onChange={e=>setStatus(e.target.value)}
                        >
                            <option value='null'>Choisir un statut</option>

                            <option value={false}>A payer</option>
                            <option value={true}>Payé</option>
                        </select> */}
                        <Switch></Switch>
                    </div>
                    {error?<span className="text-xs text-red-500 italic">{error}</span>:null}
                    <div className='flex justify-center items-center mt-6'>
                        <button
                            className="bg-green-500 py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark"
                        >
                            Valider
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
