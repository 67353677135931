import React, {useEffect, useState} from 'react';
import { axiosConfig } from '../../utils/axiosConfig'
import '../../App.css';
import RefurbsServerSide from './table/refurbsServerSide'
import RefurbsToBePaid from './table/refurbsToBePaid'
//NB : dataRefurbs est tableau de valeurs, l'accès au data se fait par index. Lorsqu'une data est ajoutée à ce tableau, l'index des data est modifiée.
//cela impacte plusieurs fonction : isRowSelectable, onRowSelectionChange, cellClicked. Il faut donc penser à les mettre à jour à chaque ajout ou modification d'ordonnancement des données du tableau
import { CircularProgress } from '@mui/material';
import { UserContext } from '../../store/UserContext';

  export default function AllRefurbs() {
    const {userState} = React.useContext(UserContext)
    const [paiement, setPaiement] = React.useState(false)
    const [countTransfer, setCountTransfer] = useState(null);
    
    useEffect(()=>{
        axiosConfig.get('apiFront/backoffice/api/refurbs/refurbsToBePaidCount')
        .then(function (response) {
            // console.log("RESPONSE",response.data)
            setCountTransfer(response.data.countTransfer || 0)
        })
        .catch(function (error) {
            console.log(error);
        });
    },[]);

        return (
            <>
            {userState.user.role==="admin"?
                <div className="shadow border rounded-lg w-1/4 mb-5">
                    <div className="flex items-center space-x-4 p-4">
                        <div className="flex items-center p-4 bg-blue-600 text-white rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" className="fill-current w-7 h-7" width="24" height="24" viewBox="0 0 24 24"><path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z"/></svg> */}
                        </div>
                        <div className="flex-1 col">
                            <p className="text-gray-500 font-semibold">Clients à payer</p>
                            <div className="flex items-baseline justify-between space-x-4">
                                <h2 className="text-2xl font-semibold">
                                    { countTransfer || countTransfer===0 ?
                                    countTransfer
                                    :
                                    <CircularProgress size={24} />
                                    }
                                </h2>
                                <button
                                    className="btn border border-black rounded-xl ml-auto p-2 text-xs hover:bg-gray-700 hover:text-white "
                                    onClick={()=>{setPaiement(!paiement)}}
                                >
                                    {paiement?"Annuler":"Sélectionner"}
                                </button>
                            </div>                            
                        </div>
                    </div>
                </div>   
            :
                null
            }
            {
                !paiement?
            <RefurbsServerSide></RefurbsServerSide>
            :
            <RefurbsToBePaid></RefurbsToBePaid>
            }
            </>

        )
    


    }
// }