import React, {useEffect, useContext} from 'react';
import axios from 'axios';
import { UserContext } from '../../../store/UserContext';


export default function FormEditUser(props) {
    const { userState } = useContext(UserContext); 
    const [submit, setSubmit] = React.useState(false);
    const [ error, setError ] = React.useState('');
    const [ user, setUser] = React.useState();
    const [ role, setRole] = React.useState();
    
  useEffect(()=>{
        const updateUserRequest = () => {
            if(submit){
                axios({
                    method:'post',
                    headers: { 'Authorization': 'Bearer ' + userState.user.token},
                    url:`${process.env.REACT_APP_ADMIN_URL}/apiFront/users/createUser`,
                    data:user,
                })
                .then(function (response) {
                    if(!response.data.error){
                        props.formFunctioToClose();
                    }else{
                        setError(response.data.message)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
                setSubmit(false)
            }
        }

        if(submit){
            updateUserRequest()
        }
    },[submit, props, user,userState.user.token])

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setUser({email:e.target.elements.email?.value,
                password : e.target.elements.password?.value,
                role : role,
                name:e.target.elements.name?.value})
        setError(null)
        setSubmit(true)
    };
    return (
        <div className='flex bg-gray-bg1 '>
            <div className='w-full max-w-md m-auto bg-white rounded-lg shadow-default py-10'>
                <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center'>
                   Création d'un nouvel utilisateur
                </h1>

                <form onSubmit={handleFormSubmit}>
                    <div>
                        <label htmlFor='firstname'>Prénom</label>
                        <input
                            type='name'
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                            id='name'
                            placeholder='Votre prénom'
                        />
                    </div>
                    <div>
                        <label htmlFor='email'>Email</label>
                        <input
                            type='email'
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                            id='email'
                            placeholder='votre email'
                        />
                    </div>
                    <div>
                        <label htmlFor='role'>Rôle</label>
                        <select
                            value={role} 
                            onChange={e=>setRole(e.target.value)}
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                        >
                            <option selected value="admin">Administrateur</option>
                            <option value="operator">Opérateur</option>
                            <option value="sav">Service Client</option>
                            <option value="manager-operator">Responsable tri</option>

                        </select>
                    </div>
                    <div>
                        <label htmlFor='password'>Mot de passe</label>
                        <input
                            type='password'
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                            id='password'
                            placeholder='Saisir un mot de passe'
                        />
                    </div>
                    {error?<span className="text-xs text-red-500 italic">{error}</span>:null}

                    <div className='flex justify-center items-center mt-6'>
                        <button
                            className="bg-green-500 py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark"
                        >
                            Valider
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
